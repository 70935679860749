import { useRef, useState } from "react";
import { ClosedViewID, OpenViewID, WithUserViewID } from "@/Store/Reducers/cache/HardCodedViews";
import { useCache } from "@/Hooks/useCache";
import { useAgent } from "@/Hooks/useAgent";
import { TicketCounts } from "@/Api/genApi.schemas";
import ViewButton from "./ViewGroup/ViewButton/ViewButton";
import ViewDropdown from "./ViewGroup/ViewDropdown/ViewDropdown";
import ViewGroup from "./ViewGroup/ViewGroup";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { viewChanged } from "../../../Store/Reducers/ticketsPageSlice";
import { AgentAvatar } from "../../../Components/Avatar/AgentAvatar";
import { View } from "@shared/Models/View";

interface ViewsBottomHalfProps {
	ticketCounts?: TicketCounts;
}

export function ViewsBottomHalf(props: ViewsBottomHalfProps) {
	const dispatch = useAppDispatch();
	const viewId = useAppSelector(state => state.ticketsPage.viewId);
	const agent = useAgent();

	const scrollContainerRef = useRef<HTMLDivElement>(null!);
	const [scrolled, setScrolled] = useState(false);

	const { cache } = useCache();

	// Casting these to Views as they are hardcoded so we know they exist.
	const openView = cache.getView(OpenViewID) as View;
	const withUserView = cache.getView(WithUserViewID) as View;
	const closedView = cache.getView(ClosedViewID) as View;

	// For adding border top on scroll
	const handleScroll = () => {
		if (scrollContainerRef.current?.scrollTop > 0) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	function setActiveView(view: View) {
		dispatch(viewChanged(view));
	}

	return (
		<div
			className={
				"h-full overflow-y-auto border-t px-3 pb-4 flex flex-col gap-1 " +
					(scrolled ? " border-gray-300 " : " border-transparent ")
			}
			style={{ scrollbarGutter: "stable" }}
			onScroll={handleScroll}
			ref={scrollContainerRef}
		>
			<ViewGroup name="Teams">
				<>
					{cache.Teams.map(team => { // Try to output a dropdown for each team.
						const agentInTeam = agent?.teams?.some(agentTeam => agentTeam.teamId == team.id);

						if (agent != null && !agent.isAdmin && !agentInTeam) {
							return;
						}

						const views = cache.Views.filter(view => view.groupName == "Teams" && view.subGroupId == team.id);

						// Used to remove the gutter for icons if no team has one.
						const anyTeamHasAnIcon = cache.Teams.filter(team => team.icon != null && team.icon != "").length > 0;

						return <ViewDropdown
							key={team.id}
							icon={team.icon != null && team.icon != "" ? team.icon : anyTeamHasAnIcon ? "" : undefined}
							name={team.name ?? ""}
							groupId={"teamId:" + team.id + ",agentId:-2"}
							views={views}
							ticketCounts={props.ticketCounts}
							activeViewId={viewId}
							setActiveView={setActiveView}
						       />;
					})}
				</>
			</ViewGroup>

			<ViewGroup name="Agents">
				<>
					{cache.Agents.map(viewAgent => {
						// If we're not an admin, check we share a team with them.
						if (agent != null && !agent.isAdmin) {
							const sharedTeamIds = agent.teamIds?.filter(id => viewAgent.teamIds?.includes(id));

							const sharedTeamFound = sharedTeamIds != null && sharedTeamIds.length > 0;

							if (!sharedTeamFound) {
								return;
							}
						}

						const view = cache.Views.find(view => view.id == "agentId:" + viewAgent.id);

						if (view != null) {
							return <ViewButton
								key={viewAgent.id}
								active={viewId == view.id}
								view={view}
								icon={<AgentAvatar agent={viewAgent} widthPx={20} hideAgentStatus />}
								setActiveView={setActiveView}
								count={props.ticketCounts?.agentCounts[viewAgent.id]}
							       />;
						} else {
							return;
						}
					})}
				</>
			</ViewGroup>

			<ViewGroup name="Utility">
				<ViewButton
					key={OpenViewID}
					active={viewId == OpenViewID}
					view={openView}
					setActiveView={setActiveView}
					count={props.ticketCounts?.statusOpen}
				/>
				<ViewButton
					key={WithUserViewID}
					active={viewId == WithUserViewID}
					view={withUserView}
					setActiveView={setActiveView}
					count={props.ticketCounts?.statusUser}
				/>
				<ViewButton
					key={ClosedViewID}
					active={viewId == ClosedViewID}
					view={closedView}
					setActiveView={setActiveView}
					count={props.ticketCounts?.statusClosed}
				/>
			</ViewGroup>
		</div>
	);
}
