import { useRef, useState } from "react";
import { DateTime } from "luxon";
import Tooltip from "@mui/material/Tooltip";
import { useCache } from "@/Hooks/useCache";
import { useAgent } from "@/Hooks/useAgent";
import api from "@/Api/Api";
import { KbBrowserModal } from "@/Components/Utility/Modals/KbBrowserModal";
import { getImageUploadHandler } from "../../../../Components/FormComponents/HtmlEditor/imageUploadHandler";
import Keys from "@shared/Components/Key/Keys";
import Key from "@shared/Components/Key/Key";
import { PreventWindowClose } from "@shared/lib/PreventWindowClose";
import { Ticket, Attachment, Action } from "@shared/Entities/EntityTypes";
import HtmlEditor from "@shared/Components/FormComponents/HtmlEditor/HtmlEditor";
import { Attachments } from "@shared/Attachments/Attachments";
import { FormHotkeys } from "@shared/Components/Utils/FormHotkeys";

interface TicketNewActionProps {
	ticket: Ticket;
	isPublic: boolean;
	clearActionPassback(): void;
	addActionPassback(action: Action): Promise<void>;
	defaultAction?: Action;
}

export function TicketNewAction(props: TicketNewActionProps) {
	const agent = useAgent();
	const { cache } = useCache();
	const [action, setAction] = useState<Action>(props.defaultAction ?? { id: 0, ticketId: props.ticket.id, isPublic: props.isPublic });
	const [noteValue, setNoteValue] = useState<string>(props.defaultAction?.noteBody ?? getNoteTemplate());
	const [files, setFiles] = useState<Attachment[]>([]);
	const [kbBrowserModalOpen, setKbBrowserModalOpen] = useState(false);

	const editorRef = useRef<null | any>(null);
	const addActionInProgress = useRef<boolean>(false);

	async function submitAction(note?: string) {
		// Stops the user submitting the same reply if they Ctrl + enter on the HtmlEditor.
		if (addActionInProgress.current == true) {
			return;
		}

		addActionInProgress.current = true;

		const actionCopy: Action = Object.assign({}, action);

		actionCopy.customCreated = DateTime.now().toISO();

		actionCopy.noteBody = note ?? noteValue;
		actionCopy.agentId = agent?.id;

		actionCopy.attachments = files;

		if (props.isPublic) {
			actionCopy.sendToUser = true;
			actionCopy.isPublic = true;
		} else {
			actionCopy.isPublic = false;
		}

		// Awaiting this to stop users being able to send the same reply if they click send multiple times.
		await props.addActionPassback(actionCopy);

		addActionInProgress.current = false;
	}

	const submitButtonText = props.isPublic ? "Send" : "Save";

	function getNoteTemplate() {
		let noteTemplate = "";

		// Only set a default note if this is a public note with a user set.
		if (props.isPublic && props.ticket.userId != null) {
			const signature = agent?.signature ? "<p>" + agent?.signature + "</p>" : "";
			const name = cache.getUser(props.ticket.userId)?.name ?? "";
			let agentGreeting = agent?.prefs.emailGreeting ?? "Hi $name,";

			if (agentGreeting === "") { // Special case so a new line isn't added.
				noteTemplate = "<p id='godeskcaret'>&nbsp;</p>" + signature;
			} else {
				agentGreeting = agentGreeting.replaceAll("$name", name);

				noteTemplate = "<p>" + agentGreeting + "</p><p id='godeskcaret'>&nbsp;</p>" + signature;
			}
		}

		return noteTemplate;
	}

	return (
		<div className="bg-white p-3 border-b border-gray-300">
			<FormHotkeys onSubmit={submitAction} onCancel={props.clearActionPassback} />

			{props.defaultAction == null ?
				<div>
					Adding: {props.isPublic ? "Reply" : "Private note"}
				</div>
				: null}
			<br />
			<Attachments attachmentUrl={cache.getAttachmentsDir()} files={files} setFiles={setFiles} apiCaller={api} />

			<PreventWindowClose stopClose={() => noteValue != "" } />

			<HtmlEditor
				value={noteValue}
				handleChange={(_key, newValue) => setNoteValue(newValue)}
				dataname="note"
				agentFeatures
				handleCancel={props.clearActionPassback}
				handleSubmit={submitAction}
				autofocus
				cannedReplies={cache.CannedReplies}
				imageUploadHandler={getImageUploadHandler(cache.getTenantId())}
				extraToolbarButtons={[{
					text: "KB Browser",
					onAction: () => setKbBrowserModalOpen(!kbBrowserModalOpen)
				}]}
				editorRef={editorRef}
			/>
			<br />
			<div>
				<Tooltip placement="top" title={<Keys label="Cancel" keys={[<Key>Esc</Key>]} />}>
					<button onClick={props.clearActionPassback} className="btn-grey mr-2">Cancel</button>
				</Tooltip>

				<Tooltip placement="top" title={<Keys label={submitButtonText} keys={[<Key>Ctrl</Key>, <Key>Enter</Key>]} />}>
					<button onClick={() => submitAction()} className="btn-blue" disabled={addActionInProgress.current}>{submitButtonText}</button>
				</Tooltip>
			</div>

			<KbBrowserModal
				isOpen={kbBrowserModalOpen}
				onClose={() => setKbBrowserModalOpen(false)}
				onLinkInsert={(text, link) => {
					if (editorRef.current != null) {
						editorRef.current.insertContent(`<a href="${link}" target="_blank">${text}<a/>`);
					}
				}}
			/>
		</div>
	);
}
