import { useAppSelector } from "@/Store/hooks";
import { RootState } from "@/Store/store";

const agentSelector = (state: RootState) => state.loggedInAgent.agent;

/** Gets the currently logged in agent. */
export function useAgent() {
	const loggedInAgent = useAppSelector(agentSelector);

	return loggedInAgent;
}
