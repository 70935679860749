import { Link } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import Tooltip from "@mui/material/Tooltip";
import { useAgent } from "@/Hooks/useAgent";
import { AgentAvatar } from "../Avatar/AgentAvatar";

interface LoggedInAgentSectionProps {
	expanded: boolean;
}

export function LoggedInAgentSection(props: LoggedInAgentSectionProps) {
	const agent = useAgent();

	const rightStyles = props.expanded ? " opacity-100" : " opacity-0";
	const logoutStyles = props.expanded ? " " : " hidden";

	return (
		<div className="flex items-center border-t border-gray-200 pt-3 w-full">
			<Link to={"/config/manage/agents/" + agent?.id} className="grow flex hover:bg-gray-100 active:bg-gray-200 p-1 rounded min-w-0">
				<div className="mr-2">
					{agent &&
						<AgentAvatar agent={agent} widthPx={40} />}
				</div>
				<div className={"whitespace-nowrap overflow-hidden transition-opacity " + rightStyles}>
					{agent?.name}
					<br />
					<span className="text-gray-600 text-sm">{agent?.jobTitle}</span>
				</div>
			</Link>
			<Tooltip arrow title="Log out" enterDelay={500} placement="right">
				<Link to="/logout" className={"shrink-0 transition-opacity  " + rightStyles + logoutStyles}>
					<div className="text-xl rounded text-gray-600 hover:bg-gray-100 active:bg-gray-200 hover:text-gray-700 flex items-center p-1 ml-1">
						<IoLogOutOutline />
					</div>
				</Link>
			</Tooltip>
		</div>
	);
}
