import { FaCircle } from "react-icons/fa";
import SlaTimeAmount from "@/Pages/Config/SLA/SlaTimeAmount";
import { Sla } from "@shared/Entities/EntityTypes";

interface PriorityGridProps {
	data?: Sla;
	handleChange(key: string, newValue: number | undefined): void;
}

export function PriorityGrid(props: PriorityGridProps) {
	return (
		<div className="grid auto-cols-min bg-white border border-gray-200 shadow-sm" style={{ margin: "20px 15px", gridTemplateColumns: "130px 1fr 1fr" }}>
			<div className="p-3 bg-gray-100 text-gray-900 font-semibold">Priority</div>
			<div className="p-3 bg-gray-100 text-gray-900 font-semibold">Response time</div>
			<div className="p-3 bg-gray-100 text-gray-900 font-semibold">Resolution time</div>

			<PriorityRow
				label="P1"
				labelColor="#F03232"
				handleChange={props.handleChange}
				responseValue={props.data?.p1Response}
				responseUnit={props.data?.p1ResponseUnit}
				responseValueName="p1Response"
				responseUnitName="p1ResponseUnit"
				resolutionValue={props.data?.p1Resolution}
				resolutionUnit={props.data?.p1ResolutionUnit}
				resolutionValueName="p1Resolution"
				resolutionUnitName="p1ResolutionUnit"
			/>

			<PriorityRow
				label="P2"
				labelColor="#F07E32"
				handleChange={props.handleChange}
				responseValue={props.data?.p2Response}
				responseUnit={props.data?.p2ResponseUnit}
				responseValueName="p2Response"
				responseUnitName="p2ResponseUnit"
				resolutionValue={props.data?.p2Resolution}
				resolutionUnit={props.data?.p2ResolutionUnit}
				resolutionValueName="p2Resolution"
				resolutionUnitName="p2ResolutionUnit"
			/>

			<PriorityRow
				label="P3"
				labelColor="#F0E332"
				handleChange={props.handleChange}
				responseValue={props.data?.p3Response}
				responseUnit={props.data?.p3ResponseUnit}
				responseValueName="p3Response"
				responseUnitName="p3ResponseUnit"
				resolutionValue={props.data?.p3Resolution}
				resolutionUnit={props.data?.p3ResolutionUnit}
				resolutionValueName="p3Resolution"
				resolutionUnitName="p3ResolutionUnit"
			/>

			<PriorityRow
				label="P4"
				labelColor="#4EDB27"
				handleChange={props.handleChange}
				responseValue={props.data?.p4Response}
				responseUnit={props.data?.p4ResponseUnit}
				responseValueName="p4Response"
				responseUnitName="p4ResponseUnit"
				resolutionValue={props.data?.p4Resolution}
				resolutionUnit={props.data?.p4ResolutionUnit}
				resolutionValueName="p4Resolution"
				resolutionUnitName="p4ResolutionUnit"
			/>

			<PriorityRow
				label="P5"
				labelColor="#D5D5D5"
				handleChange={props.handleChange}
				responseValue={props.data?.p5Response}
				responseUnit={props.data?.p5ResponseUnit}
				responseValueName="p5Response"
				responseUnitName="p5ResponseUnit"
				resolutionValue={props.data?.p5Resolution}
				resolutionUnit={props.data?.p5ResolutionUnit}
				resolutionValueName="p5Resolution"
				resolutionUnitName="p5ResolutionUnit"
			/>
		</div>
	);
}

interface PriorityRowProps {
	label: string;
	labelColor: string;
	responseValue?: number;
	responseUnit?: number;
	responseValueName: string;
	responseUnitName: string;
	resolutionValue?: number;
	resolutionUnit?: number;
	resolutionValueName: string;
	resolutionUnitName: string;
	handleChange(key: string, newValue: number | undefined): void;
}

function PriorityRow(props: PriorityRowProps) {
	return (
		<>
			<div className="border-t inline-flex items-center p-2">
				<FaCircle className="text-sm mr-2" style={{ color: props.labelColor }} />{props.label}
			</div>
			<div className="border-t p-1">
				<SlaTimeAmount
					value={props.responseValue ?? 0}
					unit={props.responseUnit}
					valueName={props.responseValueName}
					unitName={props.responseUnitName}
					onChange={props.handleChange}
				/>
			</div>
			<div className="border-t p-1">
				<SlaTimeAmount
					value={props.resolutionValue ?? 0}
					unit={props.resolutionUnit}
					valueName={props.resolutionValueName}
					unitName={props.resolutionUnitName}
					onChange={props.handleChange}
				/>
			</div>
		</>
	);
}
