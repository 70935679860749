import { Box } from "@mui/material";
import Feed from "@/Pages/home/Feed";
import { GetStartedGuide } from "@/Pages/home/GetStartedGuide";
import { AgentCard } from "@/Pages/home/AgentCard";

export default function Home() {
	document.title = "Home | GoDesk";

	return (
		<div className="h-full">
			<div className="flex items-center px-3 border-b border-gray-300" style={{ height: "6em" }}>
				<div style={{ width: "30em" }}>
					<AgentCard />
				</div>
			</div>

			<div className="flex" style={{ height: "calc(100% - 6em)" }}>
				<div className="flex flex-col border-r border-gray-300 py-3 shrink-0" style={{ width: "30em" }}>
					<div className="mb-2 text-base font-semibold px-3">Recent updates</div>
					<div className="overflow-y-auto px-3 grow">
						<Feed />
					</div>
				</div>

				<Box
					sx={{ bgcolor: "background.paper", flexGrow: 1, overflowY: "auto" }}
				>
					<GetStartedGuide />
				</Box>
			</div>
		</div>
	);
}
