import { useState } from "react";
import { MdOutlineMail } from "react-icons/md";
import { Stack } from "@mui/material";
import { AgentMenu } from "@/Components/Header/AgentMenu/AgentMenu";
import { NotificationCenterButton } from "@/Components/Header/NotificationCenter/NotificationCenterButton";
import { NeutralButton } from "@/Components/Button/NeutralButton";
import ContactSalesModal from "../Utility/Modals/ContactSalesModal";
import ContactSupportModal from "../Utility/Modals/ContactSupportModal";

export default function HeaderButtons() {
	const [contactSalesModalOpen, setContactSalesModalOpen] = useState(false);
	const [contactSupportModalOpen, setContactSupportModalOpen] = useState(false);

	const timestampSeconds = Math.floor(Date.now() / 1000);

	let daysLeftInTrialString = "";

	// Disabled for now.
	// const trialStarted = SettingsFunctions.getSettingValue(settings, "trialStarted");
	const trialStarted = null;

	if (trialStarted != null && trialStarted != "") {
		const daysLeftInTrial = Math.ceil(14 - (timestampSeconds - parseInt(trialStarted)) / 60 / 60 / 24);

		if (daysLeftInTrial != 1) {
			daysLeftInTrialString = daysLeftInTrial + " days left in trial.";
		} else {
			daysLeftInTrialString = daysLeftInTrial + " day left in trial.";
		}

		if (process.env.NODE_ENV == "development" || window.location.hostname == "localhost") {
			daysLeftInTrialString = "14 days left in trial.";
		}
	}

	return (
		<Stack direction="row" spacing={2} className="pr-5 items-center">
			{/* <button><AiOutlineQuestionCircle /></button>*/}

			{/* <button onClick={() => navigate("/get-started")} style={{height: "2em", margin: "auto 1rem auto 0"}} className="btn-grey">GoDesk Setup (30%)</button>*/}

			<div className="flex items-center">
				{trialStarted != null ?
					<>
						<div className="mr-3">
							{daysLeftInTrialString}
						</div>

						<NeutralButton
							startIcon={<MdOutlineMail />}
							onClick={() => setContactSalesModalOpen(true)}
						>
							Contact sales
						</NeutralButton>

						<ContactSalesModal isOpen={contactSalesModalOpen} closeModal={() => setContactSalesModalOpen(false)} />
					</>
					:
					<>
						<NeutralButton
							startIcon={<MdOutlineMail />}
							onClick={() => setContactSupportModalOpen(true)}
						>
							Contact support
						</NeutralButton>

						<ContactSupportModal isOpen={contactSupportModalOpen} closeModal={() => setContactSupportModalOpen(false)} />
					</>}
			</div>

			<div>
				<NotificationCenterButton />
			</div>

			<AgentMenu />
		</Stack>
	);
}
