import { useState } from "react";
import { Link } from "react-router-dom";
import { AgGridReact } from "@ag-grid-community/react";
import { ColDef } from "@ag-grid-community/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { AiOutlineSave } from "react-icons/ai";
import { ButtonBlue } from "../../Components/Button/Button";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";

export default function Billing() {
	function pdfDownloadCellRenderer(params: ICellRendererParams) {
		console.log(params);
		return (
			<div><Link to={params.value}><AiOutlineSave /></Link></div>
		);
	}

	const [rowData] = useState([
		{ Date: "01-27-2023", Title: "Billing invoice Jan", Amount: "$700", pdfLink: "test.pdf" },
		{ Date: "07-14-2023", Title: "Billing invoice Jul", Amount: "$700", pdfLink: "test.pdf" },
		{ Amount: "", pdfLink: "test.pdf" },
		{ field: "Download", pdfLink: "test.pdf" }
	]);

	const [columnDefs] = useState<ColDef[]>([
		{ field: "Date" },
		{ field: "Title" },
		{ field: "Amount" },
		{ field: "pdfLink", headerName: "Download", cellRenderer: pdfDownloadCellRenderer }
	]);

	const [defaultColumnDef] = useState<ColDef>({
		resizable: true,
		sortable: true,
		suppressMovable: true,
		flex: 1,
		minWidth: 60,
		comparator: () => 0, // Disables the effects of client side sorting but keeps the visuals.
	});

	return (
		<>
			<div className="p-4">
				<div style={{ fontSize: "24px", margin: "10px 0" }}>
					Billing
				</div>
			</div>

			<div className="ml-4 p-4 bg-white rounded-lg border border-gray-100 shadow max-w-2xl">
				<div style={{ fontSize: "20px", margin: "10px 0" }}>Billing Address</div>
				<div className="grid grid-cols-2 my-3 gap-4 px-2">
					<TextInput dataname="" label="Email address" toolTip="This is the email which the invoices are sent too." type={InputTypes.Email} />
					<TextInput dataname="" label="Phone Number" toolTip="Contact Number" type={InputTypes.Tel} />
					<div className=""></div>
					<div className="justify-self-end pt-2"><ButtonBlue label="Update" /></div>
				</div>
			</div>

			<div className="flex">
				<div className="ag-theme-alpine mt-10 ml-3" style={{ height: 250, width: 1000 }}>
					<div className="space-x-4 mb-2 flex justify-end">
						<ButtonBlue label="Download All" />
					</div>

					<AgGridReact
						className="justify-self-start"
						rowData={rowData}
						columnDefs={columnDefs}
						defaultColDef={defaultColumnDef}
						rowSelection="multiple"
					/>
				</div>
			</div>
		</>
	);
}
