import { useState } from "react";
import { Link } from "react-router-dom";
import { FaBoxes, FaCogs, FaUserClock } from "react-icons/fa";
import { AiOutlineClockCircle, AiOutlineTeam, AiOutlineUser } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { BiCopyAlt } from "react-icons/bi";
import { BsHeadset } from "react-icons/bs";
import { LuImport } from "react-icons/lu";
import { PiCode, PiPaintBrushHouseholdDuotone, PiRobotFill } from "react-icons/pi";
import { TbMailbox, TbWorldPlus } from "react-icons/tb";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useCache } from "@/Hooks/useCache";
import { ImportsModal } from "../../Components/Utility/Modals/ImportsModal";
import { useAppSelector } from "../../Store/hooks";
import { SettingInt } from "@shared/Enums/SettingEnums";

export default function ConfigMenu() {
	document.title = "Config | GoDesk";

	const [isOpen, setIsOpen] = useState(false);

	const { cache } = useCache();
	const godeskDbVersion = cache.getSettingInt(SettingInt.DB_VERSION);

	const isAdmin = useAppSelector(state => state.loggedInAgent.agent)?.isAdmin ?? false;

	return (
		<div className="max-w-3xl m-auto px-3">
			<div className="text-2xl my-5 ml-3">Config</div>

			<div className="bg-white rounded p-3 my-5 shadow">
				<div className="text-lg mb-3">Manage</div>
				<div className="flex flex-wrap gap-3">
					<ConfigMenuItem icon={<AiOutlineUser />} title="Agents" linkPath="/config/manage/agents" />

					<ConfigMenuItem icon={<BiCopyAlt />} title="Canned Replies" linkPath="/config/manage/cannedreplies" />
					<ConfigMenuItem icon={<FaBoxes />} title="Categories" linkPath="/config/manage/categories" />
					<ConfigMenuItem icon={<FaUserClock />} title="SLAs" linkPath="/config/manage/slas" />
					<ConfigMenuItem icon={<AiOutlineTeam />} title="Teams" linkPath="/config/manage/teams" />
					<ConfigMenuItem icon={<IoMdNotificationsOutline />} title="Notifications" linkPath="/config/notifications" />
				</div>
			</div>

			{isAdmin &&
				<>
					<div className="bg-white rounded p-3 my-5 shadow">
						<div className="text-lg mb-3">Email</div>
						<div className="flex flex-wrap gap-3">
							<ConfigMenuItem icon={<TbMailbox />} title="Mailboxes" linkPath="/config/manage/channels/email" />
							<ConfigMenuItem icon={<HiOutlineMail />} title="Automated Emails" linkPath="/config/email/notifications" />
						</div>
					</div>

					<div className="bg-white rounded p-3 my-5 shadow">
						<div className="text-lg mb-3">Settings</div>
						<div className="flex flex-wrap gap-3">
							<ConfigMenuItem icon={<BsHeadset />} title="Help Desk" linkPath="/config/helpdesk" />
							<ConfigMenuItem icon={<PiPaintBrushHouseholdDuotone />} title="Branding" linkPath="/config/branding" />
							<ConfigMenuItem icon={<TbWorldPlus />} title="CNAME" linkPath="/config/cname" />
							{/* <ConfigMenuItem icon={<MdAccountCircle />} title="Account" linkPath="/config/manage/account" />*/}
							{/* <ConfigMenuItem icon={<FaMoneyBillWave />} title="Billing" linkPath="/config/manage/billing" />*/}
							<ConfigMenuItem icon={<FaCogs />} title="Automations" linkPath="/config/automations" />
							<ConfigMenuItem icon={<AiOutlineClockCircle />} title="Working Hours" linkPath="/config/workday" />
							<ConfigMenuItem icon={<LuImport />} title="Imports" onClick={() => setIsOpen(true)} />
							<ConfigMenuItem icon={<PiCode />} title="Api Docs" onClick={() => window.open("/api-docs", "_blank")} />
						</div>
					</div>

					<div className="bg-white rounded p-3 my-5 shadow">
						<div className="text-lg mb-3">AI Tools</div>
						<div className="flex flex-wrap gap-3">
							<ConfigMenuItem
								className="!bg-[#EDD7FA] !border-[#C523FE] !border !text-[#8C02CD] hover:!bg-[#dfb7f6]"
								icon={<PiRobotFill color="#8C02CD" />}
								title="Assistant"
								linkPath="/config/ai/assistant"
							/>
						</div>
					</div>
				</>}

			<div className="mx-auto w-44 my-5 p-3 border rounded bg-gray-200 border-gray-300 text-center">
				GoDesk Version: {godeskDbVersion}
			</div>

			<ImportsModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
		</div>
	);
}

interface ConfigMenuItemProps {
	icon: JSX.Element;
	title: string;
	linkPath?: string;
	onClick?(): void;
	className?: string;
}

function ConfigMenuItem(props: ConfigMenuItemProps) {
	const button = <div
		className={"cursor-pointer transition text-center w-28 h-28 hover:bg-blue-200 border border-blue-200 bg-blue-100 rounded hover:shadow-sm " + props.className}
		onClick={props.onClick}
	               >
		<div className="text-4xl pt-5">
			{props.icon}
		</div>
		<div className="">
			{props.title}
		</div>
	</div>;

	return (
		props.linkPath != null ?
			<Link to={props.linkPath}>
				{button}
			</Link>
			:
			button
	);
}
