import { Navigate, useLocation } from "react-router-dom";
import { useAgent } from "@/Hooks/useAgent";

export function RequireAgentAuth({ children }: any) {
	const { pathname, search } = useLocation();

	const searchParams = new URLSearchParams(search);
	searchParams.append("redirect", pathname);

	const agent = useAgent();
	const isAuthenticated = agent != null;

	return isAuthenticated ? children : <Navigate to={ "/login?" + searchParams.toString() } />;
}
