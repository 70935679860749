import { ResBase, ResDelete } from "./ApiResponses";

export type QueryParams = {
	[key: string]: string | number | undefined | null;
};

export interface SortModelItem {
	/** Column Id to apply the sort to. */
	colId: string;
	/** Sort direction */
	sort: "asc" | "desc";
}

type StringProvider = () => string | undefined;
type StringProviderAsync = () => Promise<string | undefined>;

/**
 * Our wrapper around fetch for interfacing with the GoDesk BEs.
 * Use this by constructing your own one. Needed becuase each FE app has a different method of storing access tokens etc.
 *
 * Provides (low to high level):
 * {@link BasicFetch} Wrapper around fetch. Use sparingly to make non-standard API calls.
 * {@link BasicGet} (And others). Use for non-standard CRUD reqs.
 * Use {@link ApiCallerBaseForApi} for standard CRUD reqs to GoDesk entities.
 */
export class ApiCallerBase {
	getBaseUrl: StringProvider;
	getAccessToken: StringProviderAsync;
	requestTimeoutMs: number;

	/**
	 * @param baseUrlProvider Returns the service's URL without trailing slash. E.g. /backend or http://localhost:8080
	 * @param tokenProvider Returns an access token or undefined.
	 * @param requestTimeoutMs The amount of time in miliseconds we will wait for an API response.
	 */
	constructor(baseUrlProvider: StringProvider, tokenProvider: StringProviderAsync, requestTimeoutMs: number = 15000) {
		this.getBaseUrl = baseUrlProvider;
		this.getAccessToken = tokenProvider;
		this.requestTimeoutMs = requestTimeoutMs;

		console.debug(`API request timeout: ${requestTimeoutMs}`);
	}

	// Helper functions for CRUD ops with helpful defaults. Just use BasicFetch if you need more control.
	/**
	 * @param endpoint Must start with a leading slash. E.g. /users.
	 */
	async BasicGet<T>(endpoint: string, params?: QueryParams): Promise<ResBase<T>> {
		const options: RequestInit = {
			method: "GET"
		};

		return this.BasicDataFetch(endpoint, options, params);
	}

	/**
	 * @param endpoint Must start with a leading slash. E.g. /users.
	 */
	async BasicPost<T>(endpoint: string, body?: unknown, params?: QueryParams): Promise<ResBase<T>> {
		const options: RequestInit = {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(body)
		};

		return this.BasicDataFetch(endpoint, options, params);
	}

	/**
	 * @param endpoint Must start with a leading slash. E.g. /users.
	 */
	async BasicPut<T>(endpoint: string, body?: unknown): Promise<ResBase<T>> {
		const options: RequestInit = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(body)
		};

		return this.BasicDataFetch(endpoint, options);
	}

	/**
	 * @param endpoint Must start with a leading slash. E.g. /users.
	 */
	async BasicDelete(endpoint: string): Promise<ResDelete> {
		const options: RequestInit = {
			method: "DELETE"
		};

		return this.BasicFetch(endpoint, options);
	}

	/**
	 * Use this for entity exists checks.
	 * @param endpoint Must start with a leading slash. E.g. /tickets
	 */
	async BasicHead<T>(endpoint: string): Promise<ResBase<T>> {
		const options: RequestInit = {
			method: "HEAD"
		};

		return this.BasicFetch(endpoint, options);
	}

	/**
	 * The same as basic fetch, but it translates res.data.data -> res.data.
	 * Needed because of the Payload obj.
	 */
	async BasicDataFetch<T>(endpoint: string, options: RequestInit, params?: QueryParams): Promise<ResBase<T>> {
		const res = await this.BasicFetch<T>(endpoint, options, params);

		if (res.data != null && (res.data as any).data != null) {
			res.data = (res.data as any).data;
		}

		return res;
	}

	/**
	 * Super customisable wrapper around fetch().
	 */
	async BasicFetch<T>(endpoint: string, options: RequestInit, params?: QueryParams): Promise<ResBase<T>> {
		// Remove any undefined query params.
		if (params != null) {
			this.removeUndefinedParams(params);
		}

		// Params as any so we can pass number values instead of just strings.
		const urlParams = params ? "?" + new URLSearchParams(params as any) : "";

		// Get URL.
		let reqUrl: string;
		if (endpoint.startsWith("http")) {
			reqUrl = endpoint;
		} else {
			reqUrl = this.getBaseUrl() + endpoint + urlParams;
		}

		// Get and set our auth token.
		const headersCopy = new Headers(options.headers);

		if (!headersCopy.has("Authorization")) {
			const authToken = await this.getAccessToken();

			if (authToken != null) {
				headersCopy.set("Authorization", "Bearer " + authToken);

				options.headers = headersCopy;
			}
		}

		// Add timeout.
		if (process.env.NODE_ENV != "test" && "timeout" in AbortSignal) {
			options.signal = AbortSignal.timeout(this.requestTimeoutMs);
		}

		if (endpoint.startsWith("/settings/cname") || endpoint.startsWith("/remove-cname")) {
			options.signal = AbortSignal.timeout(60 * 1000);
		}

		const response: Promise<ResBase<T>> = fetch(reqUrl, options)
			.then(async res => {
			// console.log("res", res);

				if (res.ok) {
					const resText = await res.text();

					if (resText == undefined || resText == "") {
						return {
							data: undefined,
							successful: true,
							wasNetworkError: false
						};
					}

					const resJson = JSON.parse(resText);

					// TODO: If there's an error - the HTTP code shouldn't be 200! Fix this in the BE.
					if (resJson.errorMsg) { // Errors from Payload FormExceptions.
						return {
							errorMsg: resJson.errorMsg,
							errorCode: resJson.errorCode,
							successful: false,
							wasNetworkError: false,
							formFieldErrorCode: resJson.formFieldErrorCode,
							dataNumber1: resJson.dataNumber1,
							// Can currently only handle a single field error.
							fieldErrors: [{
								field: resJson.errorField,
								message: resJson.errorMsg
							}]
						};
					}

					return {
						data: resJson,
						pagination: resJson.pagination,
						successful: true,
						wasNetworkError: false
					};
				} else {
					return this.handleGoDeskError(res);
				}
			})
			.catch(err => {
				return this.handleNetworkError(err);
			});

		return response;
	}

	private async handleGoDeskError(res: Response): Promise<ResBase<never>> {
		if (res.status == 400 || res.status == 404 || res.status == 500) {
			const resText = await res.text();

			const resJson = resText != undefined && resText != "" ? JSON.parse(resText) : null;

			return {
				successful: false,
				errorCode: res.status,
				errorMsg: resJson?.errorMsg,
				wasNetworkError: false,
				formFieldErrorCode: resJson?.formFieldErrorCode,
				dataNumber1: resJson?.dataNumber1,
				// Can currently only handle a single field error.
				fieldErrors: [{
					field: resJson?.errorField,
					message: resJson?.errorMsg
				}]
			};
		}

		if (res.status == 401) {
			return {
				successful: false,
				errorCode: 401,
				errorMsg: "Unauthorized request.",
				wasNetworkError: false,
			};
		}

		if (res.status == 502) {
			return {
				successful: false,
				errorCode: 502,
				errorMsg: "The backend server is down for updates. Please contact us if it doesn't come back online.",
				wasNetworkError: false,
			};
		}

		console.error("Unhandled response.", res);

		return {
			successful: false,
			wasNetworkError: false,
		};
	}

	private handleNetworkError(err: any): ResBase<never> {
		if (err.message == "Failed to fetch" || (err.message == "fetch failed" && err.cause?.code == "ECONNREFUSED")) {
			// Could not reach API.
			return {
				successful: false,
				errorMsg: "Failed to reach the API server.",
				wasNetworkError: true,
			};
		} else if (err.message == "The user aborted a request.") {
			// Req timeout.
			return {
				successful: false,
				errorMsg: "Request timed out.",
				wasNetworkError: true,
			};
		} else {
			console.log("Unknown error in fetch", err);

			return {
				successful: false,
				errorMsg: err.message,
				wasNetworkError: true,
			};
		}
	}

	/** Deletes any params that are undefined. Does not return the obj. */
	private removeUndefinedParams(params: QueryParams): void {
		for (const key in params) {
			if (typeof params[key] == "undefined" || params[key] == null) {
				delete params[key];
			}
		}
	}
}
