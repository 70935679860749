import Select, { Options, StylesConfig } from "react-select";
import { Entities } from "@shared/Entities/Entities";
import { View } from "@shared/Models/View";

type ValueOption = {
	label: string;
	value: View;
};

interface ViewsDropdownProps {
	use: Entities;
	view?: View;
	setViewCallback(view: View): void;
}

export default function ViewsDropdown(props: ViewsDropdownProps) {
	function handleChange(data: any) {
		props.setViewCallback(data.value);
	}

	const customStyles: StylesConfig = {
		dropdownIndicator: (provided, state) => ({
			...provided,
			padding: "5px 7px",
		}),
		indicatorSeparator: (provided, state) => ({
			...provided,
			display: "none",
		}),
		menu: (provided, state) => ({
			...provided,
			margin: "1px",
		}),
	};

	const options: Options<ValueOption> | null = getBuiltInDropdownViews(props.use);

	let value: ValueOption | undefined;

	options?.forEach(option => {
		if (props.view?.name != null && option.value.name != null && props.view.name == option.value.name) {
			value = option;
		}
	});

	return (
		<>
			{options != null && options.length > 0 ?
				<div style={{ minWidth: "150px", maxWidth: "250px", marginRight: "10px" }}>
					<Select
						value={value}
						onChange={handleChange}
						options={options}
						styles={customStyles}
					/>
				</div>
				: null}
		</>
	);
}

export function getDefaultDropdownView(entityType: Entities): View | undefined {
	const builtInDropdownViews = getBuiltInDropdownViews(entityType);

	if (builtInDropdownViews != null && builtInDropdownViews.length > 0) {
		return builtInDropdownViews[0].value;
	}

	return undefined;
}

function getBuiltInDropdownViews(entityType: Entities): Options<ValueOption> | null {
	switch (entityType) {
		case Entities.AGENT:
			return [
				{
					label: "Active agents",
					value: {
						name: "Active agents",
						filters: {
							disabled: { type: "bool", comparison: "equals", value: false },
							deleted: { type: "bool", comparison: "equals", value: false },
							id: { type: "number", comparison: "notEqual", value: 3 } // GoDesk support agent
						}
					}
				},
				{
					label: "Disabled agents",
					value: {
						name: "Disabled agents",
						filters: {
							disabled: { type: "bool", comparison: "equals", value: true },
							deleted: { type: "bool", comparison: "equals", value: false },
							id: { type: "number", comparison: "notEqual", value: 3 } // GoDesk support agent
						}
					}
				}
			];
		case Entities.USER:
			return [
				{
					label: "Active users",
					value: {
						name: "Active users",
						filters: {
							disabled: { type: "bool", comparison: "equals", value: false },
							deleted: { type: "bool", comparison: "equals", value: false }
						}
					}
				},
				{
					label: "Disabled users",
					value: {
						name: "Disabled users",
						filters: {
							disabled: { type: "bool", comparison: "equals", value: true },
							deleted: { type: "bool", comparison: "equals", value: false }
						}
					}
				}
			];
		default:
			return null;
	}
}
