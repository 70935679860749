import { AiOutlineFile, AiOutlineFileExcel, AiOutlineFileGif, AiOutlineFileJpg, AiOutlineFileImage, AiOutlineFileText, AiOutlineFilePpt, AiOutlineFileWord, AiOutlineFileZip, AiOutlineFilePdf, AiOutlineCloseCircle } from "react-icons/ai";
import { CgRemoveR } from "react-icons/cg";
import { MdDownload, MdOutlineImageNotSupported } from "react-icons/md";
import { bytesToFormattedString, fileExtensionIsImage } from "@shared/Helpers/OtherFunctions";
import { Attachment } from "shared/src/Entities/EntityTypes";

interface ActionAttachmentProps {
	attachment: Attachment;
	removeAttachment?: (id: number) => void;
	tenantId?: string | null;
	attachmentsUrl: string;
	showPreview?: boolean;
}

export function ActionAttachment(props: ActionAttachmentProps) {

	const url = props.attachmentsUrl + props.attachment.fileNameStorage;

	const icon = getFileIcon(props.attachment.fileNameOriginal ?? "");

	let isImageFile = null;

	if (props.attachment.fileNameOriginal != null) {
		isImageFile = fileExtensionIsImage(props.attachment.fileNameOriginal);
	}

	return (
		<div style={{ position: "relative" }} className="mt-4 flex">
			<a href={url} download={props.attachment.fileNameOriginal} title={props.attachment.fileNameOriginal} style={{ color: "initial", textDecoration: "initial" }}>
				<div className="w-[200px] bg-white border border-gray-300 cursor-pointer hover:bg-gray-100 flex flex-col rounded">
					{ props.showPreview &&
						<div className="aspect-[2] overflow-hidden flex items-center justify-center">
							{isImageFile ?
								<object data={url}>
									<div className="flex flex-col items-center">
										<div className="text-center text-3xl">
											<MdOutlineImageNotSupported />
										</div>
										<div className="pl-2">Image not found</div>
									</div>
								</object>
								:
								<div className="text-3xl">{icon}</div>}
						</div>
					}

					<div className="flex gap-2 p-3">
						<button className="p-3 rounded bg-blue-200 hover:bg-blue-300 flex items-center">
							<MdDownload />
						</button>

						<div className="whitespace-nowrap overflow-hidden overflow-ellipsis text-sm font-medium">
							{props.attachment.fileNameOriginal}
							<br />
							{bytesToFormattedString(props.attachment.fileSizeBytes)}
						</div>
					</div>
				</div>
			</a>
			{props.removeAttachment &&
				<span
					className="absolute -top-2 -right-2.5 bg-white rounded-full flex items-center justify-center cursor-pointer h-6 w-6 text-2xl"
					title="Delete attachment"
					onClick={() => props.removeAttachment && props.removeAttachment(props.attachment.id)}
				>
					<AiOutlineCloseCircle />
				</span>}
		</div>
	);
}

function getFileIcon(filename: string) {
	let icon = <AiOutlineFile />;

	const fileExtension = filename?.substring(filename?.lastIndexOf(".") + 1).toUpperCase();

	if (fileExtension == "XLSX" || fileExtension == "XLS") {
		icon = <AiOutlineFileExcel />;
	} else if (fileExtension == "GIF") {
		icon = <AiOutlineFileGif />;
	} else if (fileExtension == "JPG" || fileExtension == "JPEG") {
		icon = <AiOutlineFileJpg />;
	} else if (fileExtension == "PNG") {
		icon = <AiOutlineFileImage />;
	} else if (fileExtension == "TXT") {
		icon = <AiOutlineFileText />;
	} else if (fileExtension == "PPTX" || fileExtension == "PPT") {
		icon = <AiOutlineFilePpt />;
	} else if (fileExtension == "DOC" || fileExtension == "DOCX") {
		icon = <AiOutlineFileWord />;
	} else if (fileExtension == "ZIP" || fileExtension == "7Z") {
		icon = <AiOutlineFileZip />;
	} else if (fileExtension == "PDF") {
		icon = <AiOutlineFilePdf />;
	}

	return icon;
}
