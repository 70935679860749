import { Button, ButtonProps } from "@mui/material";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

interface NeutralButtonProps extends ButtonProps {}

export function NeutralButton(props: NeutralButtonProps) {
	return (
		<Button
			variant="outlined"
			size="small"
			color="neutral"
			{...props}
		/>
	);
}

interface LoadingNeutralButtonProps extends LoadingButtonProps {}

export function LoadingNeutralButton(props: LoadingNeutralButtonProps) {
	return (
		<LoadingButton
			variant="outlined"
			size="small"
			color="neutral"
			{...props}
		/>
	);
}
