import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/Store/hooks";
import { AuthGetTokens } from "@/legacy/ApiAuth";
import { ApiGetMe } from "@/legacy/ApiCallerOld";
import { agentLoggedIn, agentLoggedOut } from "@/Store/Reducers/loggedInAgentSlice";
import { authCleared, authUpdated } from "@/Store/Reducers/authSlice";

interface UseAgentAuthOptions {
	redirectIfLoggedIn: boolean;
	loginRedirectURL: string;
	loginRedirectSearchParams?: URLSearchParams;
	logoutRedirectURL: string;
}

const defaultOptions: UseAgentAuthOptions = {
	redirectIfLoggedIn: false,
	loginRedirectURL: "/home",
	logoutRedirectURL: "/login?loggedout"
};

export function useAgentAuth(customOptions?: Partial<UseAgentAuthOptions>) {
	const options = { ...defaultOptions, ...customOptions };

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [instanceInvalid, setInstanceInvalid] = useState(false);

	const [loginPending, setLoginPending] = useState(false);
	const [loginError, setLoginError] = useState<string | null>(null);

	useEffect(() => {
		// Check if we're already logged in. Function will redirect to home if so.
		async function checkApiLoggedInAgent() {
			const res = await ApiGetMe();

			if (res.successful) {
				if (res.data?.id != null) {
					dispatch(agentLoggedIn(res.data));
					setIsLoggedIn(true);

					if (options.redirectIfLoggedIn) {
						doLoginRedirect();
					}

					return;
				}
			} else if (res.errorCode == 404) { // Instance does not exist.
				setInstanceInvalid(true);
			} else {
				console.log("Could not do checkApiLoggedInAgent(). Error in auth: " + res.errorCode + ": " + res.errorMsg);
			}

			setIsLoggedIn(false);
		}

		checkApiLoggedInAgent();
	}, []);

	async function login(email: string, password: string) {
		setLoginPending(true);

		const tokenRes = await AuthGetTokens(email, password);

		if (!tokenRes.successful) {
			setLoginError("Error in auth: " + tokenRes.errorMsg);
		}

		if (tokenRes.data != null) {
			dispatch(authUpdated({ accessToken: tokenRes.data.accessToken, refreshToken: tokenRes.data.refreshToken }));

			const loggedInAgentRes = await ApiGetMe();

			if (loggedInAgentRes.successful) {
				if (loggedInAgentRes.data?.id != null) {
					dispatch(agentLoggedIn(loggedInAgentRes.data));

					setIsLoggedIn(true);
					setLoginPending(false);
					doLoginRedirect();
					return;
				} else {
					setLoginError("Error in auth: Could not log you in.");
				}
			} else {
				setLoginError("Error in auth: " + loggedInAgentRes.errorMsg);
			}
		} else if (tokenRes.errorCode == 401) {
			setLoginError("Incorrect Credentials.");
		} else if (tokenRes.errorCode == 404) {
			setLoginError("This instance of GoDesk does not exist. Are you on the correct URL?");
		} else {
			setLoginError("Unknown auth error.");
		}

		setIsLoggedIn(false);
		setLoginPending(false);
	}

	function doLoginRedirect() {
		const redirectParam = options.loginRedirectSearchParams?.get("redirect");

		if (options.loginRedirectSearchParams != null && redirectParam != undefined && redirectParam != "") {
			options.loginRedirectSearchParams.delete("redirect");

			navigate({ pathname: redirectParam, search: options.loginRedirectSearchParams.toString() });
		} else {
			navigate(options.loginRedirectURL);
		}
	}

	function logout() {
		dispatch(authCleared());
		dispatch(agentLoggedOut());
		setIsLoggedIn(false);

		navigate(options.logoutRedirectURL);
	}

	return {
		isLoggedIn,
		instanceInvalid,

		login,
		loginPending,
		loginError,

		logout
	};
}
