import { useGetOneAgents } from "@/Api/genApi";
import { AgentViewerNewForm } from "@/Pages/Config/Agents/AgentViewerNewForm";
import { EntityReqErrorWrapper } from "@/Fragments/EntityViewer/EntityReqErrorWrapper";

interface AgentViewerNewProps {
	id: number;
}

export function AgentViewerNew(props: AgentViewerNewProps) {
	const getAgentReq = useGetOneAgents(props.id);

	return <EntityReqErrorWrapper
		res={getAgentReq}
		render={data => {
			if (data.data == null) {
				return "Error";
			} else {
				return <AgentViewerNewForm defaultData={data.data} />;
			}
		}}
	       />;

}
