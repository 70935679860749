import { AgentAvatar } from "@/Components/Avatar/AgentAvatar";
import { useAgent } from "@/Hooks/useAgent";
import { AgentStatusSelector } from "@/Pages/home/AgentStatusSelector";

export function AgentCard() {
	const agent = useAgent();

	return (
		<div className="flex">
			<div style={{ marginRight: "10px" }}>
				<AgentAvatar agent={agent} widthPx={50} />
			</div>
			<div>
				<div>{agent?.name}</div>
				<div>{agent?.jobTitle}</div>
				<AgentStatusSelector />
			</div>
		</div>
	);
}
