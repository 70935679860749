import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import ApiDropdown from "@/Components/FormComponents/ApiDropdown/ApiDropdown";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { TimezoneDropdown } from "@/Components/FormComponents/TimezoneDropdown/TimezoneDropdown";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import W_MultiEntitySelect from "@/Components/FormComponents/MultiEntitySelect/W_MultiEntitySelect";
import W_Radio from "@/Components/FormComponents/Radio/W_Radio";
import Timerange from "@/Components/FormComponents/Timerange/Timerange";
import InlineText from "@/Components/FormComponents/InlineText/InlineText";
import SlaTimeAmount from "@/Pages/Config/SLA/SlaTimeAmount";
import { Tickbox } from "@shared/Components/FormComponents/Tickbox/Tickbox";
import { W_Tickbox } from "@shared/Components/FormComponents/Tickbox/W_Tickbox";
import { EmojiKeyboard } from "@shared/Components/FormComponents/EmojiKeyboard/EmojiKeyboard";
import { Entities } from "@shared/Entities/Entities";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import W_Dropdown from "@shared/Components/FormComponents/Dropdown/W_Dropdown";
import W_TextArea from "@shared/Components/FormComponents/TextArea/W_TextArea";

const toolTipText = "This is the form component's tooltip.";

export function DesignFormComponents() {
	const [mandatory, setMandatory] = useState(true);
	const [label, setLabel] = useState(true);
	const [disabled, setDisabled] = useState(false);
	const [errors, setErrors] = useState(false);
	const [toolTip, setTooltip] = useState<string | undefined>(toolTipText);

	const { control, setError, clearErrors } = useForm({
		defaultValues: {
			W_TextInput: "",
			W_Dropdown: 1,
			W_CacheDropdown: 1,
			W_MultiEntitySelect: undefined,
			W_Radio: 1,
			W_Tickbox: false,
			W_TextArea: ""
		}
	});

	function addErrors() {
		setError("W_TextInput", { message: "Error message" });
		setError("W_Dropdown", { message: "Error message" });
		setError("W_CacheDropdown", { message: "Error message" });
		setError("W_MultiEntitySelect", { message: "Error message" });
		setError("W_Radio", { message: "Error message" });
		setError("W_Tickbox", { message: "Error message" });
		setError("W_TextArea", { message: "Error message" });
	}

	return (
		<div className="container mx-auto p-8 grid grid-cols-3">

			<div>
				<div className="text-2xl mb-4 flex flex-col gap-3">GoDesk Design System
					<Link to="/" className="text-blue-700">Home</Link>
					<Link to="/design" className="text-blue-700">Design</Link>
				</div>
				<Tickbox valueName="" label="Label" value={label} onChange={(_key, value) => setLabel(value)} />
				<Tickbox valueName="" label="Tooltip" value={toolTip != undefined} onChange={(_key, value) => setTooltip(value ? toolTipText : undefined)} />
				<Tickbox valueName="" label="Mandatory" value={mandatory} onChange={(_key, value) => setMandatory(value)} />
				<Tickbox valueName="" label="Disabled" value={disabled} onChange={(_key, value) => setDisabled(value)} />
				<Tickbox valueName="" label="Errors" value={errors} onChange={(_key, value) => {setErrors(value); value ? addErrors() : clearErrors();}} />
			</div>

			<div className="w-[300px] flex flex-col">
				<div className="my-5 text-2xl">Standard</div>

				<W_TextInput
					control={control}
					dataname="W_TextInput"
					label={label && "TextInput"}
					toolTip={toolTip}
					mandatory={mandatory}
					disabled={disabled}
				/>

				<W_Dropdown
					control={control}
					dataname="W_Dropdown"
					options={[]}
					label={label && "Dropdown"}
					toolTip={toolTip}
					mandatory={mandatory}
					disabled={disabled}
				/>

				<W_CacheDropdown
					control={control}
					dataname="W_CacheDropdown"
					cacheSlice={CacheSlices.Agents}
					label={label && "CacheDropdown"}
					toolTip={toolTip}
					mandatory={mandatory}
					disabled={disabled}
				/>

				<W_MultiEntitySelect
					control={control}
					dataname="W_MultiEntitySelect"
					cacheSlice={CacheSlices.Agents}
					label={label && "MultiEntitySelect"}
					mandatory={mandatory}
					toolTip={toolTip}
				/>

				<ApiDropdown
					dataname=""
					entity={Entities.AGENT}
					searchableField=""
					onChange={() => {}}
					label={label && "ApiDropdown"}
					toolTip={toolTip}
					mandatory={mandatory}
					disabled={disabled}
				/>

				<TimezoneDropdown
					dataname=""
					onChange={() => {}}
					value=""
					disabled={disabled}
					label={label && "TimezoneDropdown"}
					toolTip={toolTip}
					mandatory={mandatory}
				/>
			</div>

			<div className="w-[300px] flex flex-col">
				<EmojiKeyboard
					label="EmojiKeyboard"
					updateValue={() => {}}
				/>

				<W_Radio
					control={control}
					dataname="W_Radio"
					options={[{ label: "Option 1", value: 1 }, { label: "Option 2", value: 2 }, { label: "Option 2", value: 2 }]}
				/>

				<W_Tickbox
					control={control}
					valueName="W_Tickbox"
					dataname="W_Tickbox"
					label="Tickbox"
				/>

				<W_TextArea
					control={control}
					dataname="W_TextArea"
					disabled={disabled}
					label={label && "TextArea"}
					toolTip={toolTip}
					mandatory={mandatory}
				/>

				<SlaTimeAmount
					valueName=""
					onChange={() => {}}
					unitName=""
					label={label && "TimeAmount"}
					mandatory={mandatory}
				/>

				<Timerange
					valueName=""
					value=""
					onChange={() => {}}
					label={label && "TimeAmount"}
					disabled={disabled}
				/>

				<InlineText
					dataname=""
					handleSubmit={() => {}}
					value="InlineText"
				/>
			</div>
		</div>
	);
}
