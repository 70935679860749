import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import OtherFunctions from "../../../Helpers/OtherFunctions";
import { Button, ButtonRed } from "../../../Components/Button/Button";
import ConditionManager from "./AutomationViewerComponents/ConditionManager";
import ActionManager from "./AutomationViewerComponents/ActionManager";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { Automation, AutomationAction, AutomationCondition } from "@shared/Entities/EntityTypes";

interface AutomationViewerProps {
	id: number;
	data?: Automation;
	createNew(data: Automation): void;
	updateData(data: Automation): void;
	delete(): Promise<void>;
}

export default function AutomationViewer(props: AutomationViewerProps) {
	const [internalData, setInternalData] = useState<Automation | undefined>(props.data);
	const navigate = useNavigate();
	const { handleSubmit } = useForm();

	useEffect(() => {
		// Override internal data when data changes in props.
		const dataCopy = Object.assign({}, props.data);

		if (dataCopy.actions == null || dataCopy.actions.length < 1) {
			dataCopy.actions = [{ id: -1 }];
		}
		if (dataCopy.conditions == null || dataCopy.conditions.length < 1) {
			dataCopy.conditions = [{ id: -1 }];
		}

		setInternalData(dataCopy);
	}, [props.data]);

	function handleChangeInFormComponent(key: string, newValue: any) {
		const dataCopy = Object.assign({}, internalData);
		const newData = OtherFunctions.SafeChangeProperty(dataCopy, key, newValue);

		setInternalData(newData);
	}

	function cancelButtonClick() {
		navigate("/config/automations");
	}

	async function deleteButtonClick() {
		await props.delete();
		navigate("/config/automations");
	}

	function formSubmit() {
		const dataCopy = Object.assign({}, internalData);

		dataCopy.actions?.forEach(action => {
			if (action.id == null || action.id < 0) {
				action.id = undefined;
			}
		});

		dataCopy.conditions?.forEach(condition => {
			if (condition.id == null || condition.id < 0) {
				condition.id = undefined;
			}
		});

		if (internalData != null) {
			if (internalData.id > 0) {
				props.updateData(internalData);
			} else {
				props.createNew(internalData);
			}
		}

		toast.success("Success.");
		navigate("/config/automations");
	}

	function updateActions(data: AutomationAction[]) {
		const dataCopy = Object.assign({}, internalData);
		dataCopy.actions = data;

		setInternalData(dataCopy);
	}

	function updateConditions(data: AutomationCondition[]) {
		const dataCopy = Object.assign({}, internalData);
		dataCopy.conditions = data;

		setInternalData(dataCopy);
	}

	return (
		props.data != null ?
			<form onSubmit={handleSubmit(formSubmit)} className="max-w-3xl">
				<div style={{ padding: "5px 15px" }}>
					<TextInput
						value={props.data.name}
						dataname="name"
						handleSubmit={handleChangeInFormComponent}
						label="Name"
						mandatory
						autoFocus={props.id < 0}
						maxLength={45}
					/>
				</div>

				<div className="border-t border-gray-400 px-2 py-5">
					<div className="font-semibold mb-5">
						Conditions:
					</div>

					<ConditionManager data={internalData?.conditions ?? []} updateData={updateConditions} />
				</div>

				<div className="border-y border-gray-400 px-2 py-5 mb-5">
					<div className="font-semibold mb-5">
						Actions:
					</div>

					<ActionManager data={internalData?.actions ?? []} updateData={updateActions} />
				</div>

				<div className="flex items-center">
					{props.id > 0 ?
						<>
							<Button className="ml-4" onClick={cancelButtonClick} label="Cancel" />
							<input type="submit" className="ml-2 btn-blue" value="Update" />
							<ButtonRed className="ml-2" label="Delete" onClick={deleteButtonClick} />
						</>
						:
						<>
							<Button className="ml-4" onClick={cancelButtonClick} label="Cancel" />
							<input type="submit" className="ml-2 btn-blue" value="Create" />
						</>}
				</div>
			</form>
			:
			null
	);
}
