"use client";

import { ReactNode, useId, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as EditorApi } from "tinymce/tinymce";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { Popover } from "@mui/material";
import { CannedReply } from "../../../Entities/EntityTypes";

import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/skin.min.css";

import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/imagetools"; // This should show a resize box when an image has been selected but I can't get it working. Possibly due to css imports... https://github.com/tinymce/tinymce-react/issues/56
import "tinymce/plugins/charmap";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/paste";
import "tinymce/plugins/table";
import "tinymce/plugins/help";
import "tinymce/plugins/autoresize";

export interface ToolbarButton {
	text: string;
	onAction: (api: EditorApi | null) => void;
}

export interface HtmlEditorProps {
	value: string;
	handleChange(key: string, newValue: string): void;
	dataname: string;
	label?: ReactNode;
	mandatory?: boolean;
	autofocus?: boolean;
	heightPx?: number;
	isNewTicketModal?: boolean;
	resizable?: boolean;

	/** Enables agent-only features like canned text, code editing, KB article links. */
	agentFeatures?: boolean;

	handleCancel(): void;
	handleSubmit(note?: string | undefined): void;

	imageUploadHandler?(blobInfo: any, success: (url: string) => void, failure: (err: string, options?: any) => void): void;
	cannedReplies?: CannedReply[];

	extraToolbarButtons?: ToolbarButton[];
	editorRef?: React.MutableRefObject<EditorApi | null>;
}

export default function HtmlEditor(props: HtmlEditorProps) {
	const controlId = useId();
	const [showEmojiKeyboard, setShowEmojiKeyboard] = useState(false);
	const emojiPickerTarget = useRef<null | HTMLElement>(null);
	const editorRef = useRef<null | EditorApi>(null);

	function onChange(newValue: string, editor: EditorApi) {
		props.handleChange(props.dataname, newValue);
	}

	function setupCallback(editor: EditorApi) {
		// Save reference to the EditorApi.
		editorRef.current = editor;

		if (props.editorRef != null) {
			props.editorRef.current = editor;
		}

		editor.shortcuts.add("ctrl+13", "Submit Action", () => { props.handleSubmit(editor.getContent()); }); // ctrl + enter

		editor.on("keydown", ((e) => { // Can't use shortcuts.add() for esc key.
			if (e.key == "Escape") {
				props.handleCancel();
			}
		}));

		if (props.agentFeatures) {
			addAutoCompleter(editor, props.cannedReplies);

			const toggleEmojiKeyboard = () => setShowEmojiKeyboard((value) => !value);

			addMenuButton(editor, addNewCannedReplyCallback, toggleEmojiKeyboard, props.cannedReplies);

			props.extraToolbarButtons?.forEach(button =>
				editor.ui.registry.addButton("extraButton", { // Need dynamic names to work properly.
					text: button.text,
					onAction: () => button.onAction(editorRef.current)
				})
			);
		}
	}

	function addNewCannedReplyCallback() {
		window.open("/config/manage/cannedreplies/-1");
	}

	const labelMandatory = props.mandatory == true ? <span className="Form_Label_Mandatory">*</span> : null;
	const labelJsx = <label htmlFor={controlId}>{props.label}{labelMandatory}</label>;

	let toolbar = "undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image | link ";

	if (props.agentFeatures) {
		toolbar = "undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | cannedreplies | image code | link emojipicker | extraButton";
	}

	if (props.isNewTicketModal) {
		toolbar = "bold italic underline strikeThrough | bullist numlist | link emojipicker | code ";
	}

	function onEmojiSelect(value: any) {
		if (editorRef.current != null) {
			editorRef.current.insertContent(value.native);
		}

		setShowEmojiKeyboard(false);
	}

	function onInit() {
		/*
		 * This is a work around to get the ref of the emoji button then set the position of emoji picker to be under the button.
		 * Their APi does not have a callback with a ref to the EditorApi we could use when adding the button.
		 */
		const emojiPickerButton = editorRef.current?.getContainer().querySelector("button[title=\"Emoji Picker\"]");

		if (emojiPickerButton != null) {
			emojiPickerTarget.current = emojiPickerButton as HTMLElement;
		}
	}

	const menuBar = props.isNewTicketModal ? false : "edit view insert format tools table help";
	const maxHeight = props.isNewTicketModal ? 277 : undefined;

	return (
		<div className="Form_Component">
			<div className="block text-sm">
				{labelJsx}
			</div>

			{/* Be careful with this component! The react wrapper around tinymce can override some default behaviour with the props in confusing ways.
				Check the source - Editor.js in react_modules - if in doubt. */}
			<Editor
				id={controlId}
				value={props.value}
				onEditorChange={onChange}
				onInit={onInit}

				init={{
					content_style: "body { font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif }",
					browser_spellcheck: true,
					gecko_spellcheck: true,
					height: props.heightPx ?? 400,
					menubar: menuBar,
					editor_selector: "textarea", // change this value according to your HTML
					max_height: maxHeight,
					autoresize_on_init: false,
					contextmenu: false,
					statusbar: false,
					relative_urls: false,
					remove_script_host: false,
					document_base_url: window.location.protocol + "//" + window.location.host,
					paste_data_images: true,
					images_upload_handler: props.imageUploadHandler,
					setup: setupCallback,
					plugins: `autolink lists link image imagetools charmap searchreplace visualblocks code fullscreen paste table help ${props.isNewTicketModal || props.resizable ? "autoresize" : ""}`,
					toolbar_mode: "wrap",
					toolbar: toolbar,
					init_instance_callback: function (editor) {
						if (props.autofocus) {
							const caretNode = editor.dom.select("p#godeskcaret");

							if (caretNode.length > 0) {
								editor.selection.select(caretNode[0]);
								editor.selection.collapse(true);
								editor.execCommand("mceInsertContent", false, "<p></p>");
							} else {
								editor.selection.select(editor.getBody(), true);
								editor.selection.collapse(false);
								editor.execCommand("mceInsertContent", false, "");
							}
						}
					}
				}}
			/>

			<Popover
				anchorEl={emojiPickerTarget.current}
				open={showEmojiKeyboard}
				onClose={() => setShowEmojiKeyboard(false)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
			>
				<Picker
					data={data}
					onEmojiSelect={onEmojiSelect}
					theme="light"
					maxFrequentRows={1}
					autoFocus
					perLine={8}
				/>
			</Popover>
		</div>
	);
}

function addAutoCompleter(editor: EditorApi, cannedReplyCache?: CannedReply[]) {
	const insertOptions: {
		text: string;
		value: string;
	}[] = [];

	if (cannedReplyCache != null) {
		cannedReplyCache.forEach((cannedreply: CannedReply) => {
			if (cannedreply.name != null && cannedreply.note != null) {
				insertOptions.push({ text: cannedreply.name, value: cannedreply.note });
			}
		});

		// Sort the insertOptions array alphabetically by the 'text' property
		insertOptions.sort((a, b) => (a.text ?? "").localeCompare(b.text ?? ""));
	}

	const onAction = function (autocompleterApi: any, rng: any, value: string) {
		editor.selection.setRng(rng);
		editor.insertContent(value);
		editor.insertContent("<p></p>");
		autocompleterApi.hide();
	};

	const getMatchedChars = function (pattern: string) {
		return insertOptions.filter(function (char) {
			return char.text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
		});
	};

	editor.ui.registry.addAutocompleter("cannedReplyAutocompleter", {
		ch: "/",
		minChars: 0,
		columns: 1,
		onAction: onAction,
		fetch: function (pattern) {
			return new Promise(function (resolve) {
				const results = getMatchedChars(pattern).map(function (char) {
					return {
						value: char.value,
						text: char.text
					};
				});
				resolve(results);
			});
		}
	});
}

function addMenuButton(editor: EditorApi, addNewCannedReplyCallback: Function, toggleEmojiKeyboard: Function, cannedReplyCache?: CannedReply[]) {
	const noneFoundItem = {
		type: "menuitem",
		text: "No replies found."
	};

	let options: any = [noneFoundItem];

	if (cannedReplyCache != null) {
		options = []; // Remove noneFoundItem;

		// Create a shallow copy of the cannedReplyCache array and then sort it.(Had to do a shallow copy to avoid it bricking the editor.)
		const sortedCannedReplyCache = [...cannedReplyCache].sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));

		sortedCannedReplyCache.forEach((cannedreply: CannedReply) => {
			if (cannedreply.name != null && cannedreply.note != null) {
				options.push({
					type: "menuitem",
					text: cannedreply.name,
					onAction: function () {
						editor.insertContent(cannedreply.note ?? "");
						editor.insertContent("<p></p>");
					}
				});
			}
		});
	}

	options.push(
		{
			type: "separator"
		},
		{
			type: "menuitem",
			icon: "plus",
			text: "Add new canned reply",
			onAction: function () {
				addNewCannedReplyCallback();
			}
		}
	);

	editor.ui.registry.addMenuButton("cannedreplies", {
		text: "Insert Canned Reply",
		fetch: function (callback) {
			callback(options);
		}
	});

	editor.ui.registry.addButton("emojipicker", {
		icon: "emoji",
		tooltip: "Emoji Picker",
		onAction: () => toggleEmojiKeyboard()
	});
}
