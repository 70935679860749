import { getAccessToken } from "@/legacy/ApiAuth";
import { ApiCallerBaseForApi } from "@shared/Api/ApiCallerBaseForApi";

export function getApiUrl() {
	if (process.env.NODE_ENV == "development") {
		return `http://${window.location.hostname}:8080/api`;
	}

	return "/backend/api";
}

const isProsperna = window.location.href.includes("prosperna");

const api = new ApiCallerBaseForApi(
	getApiUrl,
	getAccessToken,
	isProsperna ? 180000 : undefined
);

export default api;
