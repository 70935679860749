import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { BiCheckCircle } from "react-icons/bi";
import { IoArrowForwardOutline } from "react-icons/io5";
import { AiOutlineExport } from "react-icons/ai";
import { PiPlusBold } from "react-icons/pi";
import { TicketMassUpdateReq } from "@/legacy/ApiResponse";
import { ApiMassUpdateTickets } from "@/legacy/ApiCallerOld";
import { HeaderTabEntities } from "@/Components/Header/GlobalTab";
import NewTicketModal from "@/Components/Utility/Modals/NewTicketModal/NewTicketModal";
import { Layout } from "@/Api/genApi.schemas";
import { useGetAllCollisionNotes } from "@/Api/genApi";
import OtherFunctions from "@/Helpers/OtherFunctions";
import { useAgent } from "@/Hooks/useAgent";
import { globalTabAdded } from "../../../Store/Reducers/globalTabsSlice";
import EntityTable, { EntityTableButton } from "../EntityTable";
import { useAppDispatch } from "../../../Store/hooks";
import ReAssignTicketsModal from "../../Utility/Modals/ReAssignTicketsModal";
import { usePromiseModalHook } from "../../Utility/Modals/PromiseModalHook";
import { ExportTicketsModal } from "../../Utility/Modals/ExportTicketsModal";
import { TableSort } from "@shared/Enums/Enums";
import { TicketStatuses } from "@shared/Enums/Enums";
import { Ticket } from "@shared/Entities/EntityTypes";
import { View } from "@shared/Models/View";
import { Entities } from "@shared/Entities/Entities";

interface TicketTableProps {
	// TODO: Make mandatory once view is no longer used.
	viewId?: string | number;

	showFiltersPanel?: boolean;
	/**
	 * @deprecated Use viewId.
	 */
	view?: View;

	page?: number;
	onPageChange?(newPage: number): void;

	sort?: TableSort;
	onSortChange?(newSort?: TableSort): void;

	defaultNewTicket?: Ticket;

	layout: Layout | null;
}

export function TicketTable(props: TicketTableProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const agent = useAgent();
	const { isOpen: reAssignModalisOpen, beginModal, dismissModal: dismissReAssignModal } = usePromiseModalHook();
	const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
	const [reassignTicketsIds, setReassignTicketsIds] = useState<number[]>([]);
	const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
	const collisionNotesReq = useGetAllCollisionNotes("TICKET", { query: {
		refetchInterval: 10000
	} });


	function onRowDoubleClick(id: number, rowData: Ticket) {
		const tabTitle = rowData.description != null ? rowData.description : "NO TAB TITLE";

		dispatch(globalTabAdded({ id: id, title: tabTitle, entity: HeaderTabEntities.TICKETS }));
		navigate("/tickets/" + id);
	}

	async function massUpdate(req: TicketMassUpdateReq) {
		// Send req.
		const res = await ApiMassUpdateTickets(req);

		// Display errors.
		if (!res.successful && res.errorMsg) {
			toast.error(res.errorMsg);
		}
	}

	async function onMultiDelete(ids: number[]) {
		const req: TicketMassUpdateReq = {
			ids: [...ids],
			deleted: true
		};

		await massUpdate(req);
	}

	async function closeTickets(ids: number[]) {
		const req: TicketMassUpdateReq = {
			ids: [...ids],
			statusId: TicketStatuses.CLOSED
		};

		await massUpdate(req);
	}

	async function reassignPromise(ids: number[]) {
		setReassignTicketsIds(ids);
		await beginModal();
	}

	const multiSelectButtons: EntityTableButton[] = [
		{
			label: "Close",
			icon: <BiCheckCircle />,
			onClick: closeTickets
		},
		{
			label: "Reassign",
			icon: <IoArrowForwardOutline />,
			onClick: reassignPromise
		}
	];

	const customButtons: EntityTableButton[] = [
		{
			label: "Export",
			icon: <AiOutlineExport />,
			onClick: async () => setExportModalIsOpen(true)
		}
	];

	const newButtonReplacement: EntityTableButton = {
		icon: <PiPlusBold />,
		label: "New",
		onClick: async () => setNewTicketModalOpen(true)
	};

	return (
		<>
			<EntityTable
				entity={Entities.TICKET}
				viewId={props.viewId}
				view={props.view}
				newButtonReplacement={newButtonReplacement}
				onRowDoubleClickOverride={onRowDoubleClick}
				multiDeleteOverride={onMultiDelete}
				multiSelectButtons={multiSelectButtons}
				customButtons={customButtons}
				newEntityUrl="/tickets/-1"
				editEntityUrl="/tickets/"
				showFiltersPanel={props.showFiltersPanel}
				page={props.page}
				onPageChange={props.onPageChange}
				sort={props.sort}
				onSortChange={props.onSortChange}
				layout={props.layout}
				collisionNotes={OtherFunctions.filterCollisionNotes(collisionNotesReq.data, agent?.id)}
			/>

			<ReAssignTicketsModal
				closeModal={dismissReAssignModal}
				ids={reassignTicketsIds}
				isOpen={reAssignModalisOpen}
			/>

			<ExportTicketsModal
				isOpen={exportModalIsOpen}
				viewId={props.viewId}
				view={props.view}
				closeModal={() => setExportModalIsOpen(false)}
			/>

			<NewTicketModal
				isOpen={newTicketModalOpen}
				onClose={() => setNewTicketModalOpen(false)}
				defaultNewTicket={props.defaultNewTicket}
			/>
		</>
	);
}
