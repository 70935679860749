import { useSearchParams } from "react-router-dom";

interface ReportingSidebarButtonProps {
	name: string;
	activeReport: string;
}

export function ReportingSidebarButton(props: ReportingSidebarButtonProps) {
	const [searchParams, setSearchParams] = useSearchParams();

	const active = props.activeReport == props.name.toLowerCase().replaceAll(" ", "-");

	const activeStyles = active ? " bg-blue-50 " : " hover:bg-gray-100";

	function activate() {
		searchParams.set("report", props.name.toLowerCase().replaceAll(" ", "-"));
		setSearchParams(searchParams);
	}

	return (
		<button className={"px-3 py-1 flex shadow-none my-0.5 w-full text-left rounded text-gray-900 " + activeStyles} onClick={activate}>
			{props.name}
		</button>
	);
}
