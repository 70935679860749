import { useEffect, useState } from "react";
import "react-treeview/react-treeview.css";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineClockCircle, AiOutlineSetting, AiOutlineTeam, AiOutlineUser } from "react-icons/ai";
import { FaBoxes, FaCogs, FaPaintBrush, FaUserClock } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BiCopyAlt } from "react-icons/bi";
import { BsHeadset } from "react-icons/bs";
import { MdOutlineNotifications } from "react-icons/md";
import { LuImport } from "react-icons/lu";
import { PiCode, PiPaintBrushHouseholdDuotone, PiRobotFill } from "react-icons/pi";
import { TbMailbox, TbWorldPlus } from "react-icons/tb";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useAppSelector } from "../../Store/hooks";
import { ImportsModal } from "../../Components/Utility/Modals/ImportsModal";

export default function ConfigTreeView() {
	const isAdmin = useAppSelector(state => state.loggedInAgent.agent)?.isAdmin ?? false;
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="py-3 px-2">
			<Link to="/config"><div className="text-base hover:bg-gray-200 active:bg-gray-300 px-1 py-0.5 flex items-center rounded"><AiOutlineSetting /> <div className="ml-1">Config</div></div></Link>

			<div className="ml-1">
				<div className="mt-3 mb-1 font-semibold">Manage</div>
				<TreeLink url="/config/manage/agents" label="Agents" icon={<AiOutlineUser />} />

				<TreeLink url="/config/manage/cannedreplies" label="Canned Replies" icon={<BiCopyAlt />} />
				<TreeLink url="/config/manage/categories" label="Categories" icon={<FaBoxes />} />
				<TreeLink url="/config/manage/slas" label="SLAs" icon={<FaUserClock />} />
				<TreeLink url="/config/manage/teams" label="Teams" icon={<AiOutlineTeam />} />
				<TreeLink url="/config/notifications" label="Notifications" icon={<IoMdNotificationsOutline />} />

				{isAdmin &&
					<>
						<div className="mt-3 mb-1 font-semibold">Email</div>
						<TreeLink url="/config/manage/channels/email" label="Mailboxes" icon={<TbMailbox />} />
						<TreeLink url="/config/email/notifications" label="Automated Emails" icon={<HiOutlineMail />} />

						<div className="mt-3 mb-1 font-semibold">Settings</div>
						<TreeLink url="/config/helpdesk" label="Help Desk" icon={<BsHeadset />} />
						<TreeLink url="/config/branding" label="Branding" icon={<PiPaintBrushHouseholdDuotone />} />
						<TreeLink url="/config/cname" label="CNAME" icon={<TbWorldPlus />} />
						{/* <TreeLink url="/config/manage/account" label="Account" icon={<MdAccountCircle/>} />*/}
						{/* <TreeLink url="/config/manage/billing" label="Billing" icon={<FaMoneyBillWave/>} />*/}
						<TreeLink url="/config/automations" label="Automations" icon={<FaCogs />} />
						<TreeLink url="/config/workday" label="Working Hours" icon={<AiOutlineClockCircle />} />

						<TreeLink onClick={() => setIsOpen(true)} label="Imports" icon={<LuImport />} />
						<TreeLink onClick={() => window.open("/api-docs", "_blank")} label="Api Docs" icon={<PiCode />} />

						<div className="mt-3 mb-1 font-semibold">AI Tools</div>
						<TreeLink url="/config/ai/assistant" label="Knowledge Base assistant" icon={<PiRobotFill />} />
					</>}
			</div>

			<ImportsModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
		</div>
	);
}

interface TreeLinkProps {
	url?: string;
	onClick?(): void;
	label: string;
	icon: JSX.Element;
}

function TreeLink(props: TreeLinkProps) {
	const [active, setActive] = useState<boolean>(false);
	const location = useLocation();

	useEffect(() => {
		setActive(props.url != null && location.pathname.includes(props.url));
	}, [location, props.url]);

	const activeStyles = active ? " bg-gray-200 " : "";

	const button = <div onClick={props.onClick} className={"hover:bg-gray-200 active:bg-gray-300 py-0.5 px-3 rounded flex items-center cursor-pointer " + activeStyles}>
		{props.icon}
		<div className="ml-1">
			{props.label}
		</div>
	</div>;

	return (
		props.url != null ?
			<Link to={props.url} className="block">
				{button}
			</Link>
			:
			button
	);
}
