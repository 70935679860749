import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button } from "@/Components/Button/Button";
import api from "@/Api/Api";
import { ApiUpdateSettings } from "@/legacy/ApiCallerOld";
import { useCache } from "@/Hooks/useCache";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import TextArea from "@shared/Components/FormComponents/TextArea/TextArea";
import { Setting } from "@shared/Entities/EntityTypes";
import { SettingString } from "@shared/Enums/SettingEnums";

export function AiAssistantPage() {
	const [assistantGuidelines, setAssistantGuidelines] = useState<string>();
	const [businessDetails, setBusinessDetails] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>();
	const { refreshCache } = useCache();

	// First get the values from the BE and DB
	useEffect(() => {
		async function getGptValues() {
			setIsLoading(true);
			const res = await api.BasicGet<Setting[]>("/settings/from_keys", { "keys": "gpt.prompts.company,gpt.prompts.kbRules" });

			if (res.successful) {
				const businessDetails = res.data?.find(settingsData => settingsData.key == SettingString.GPT_COMPANY_PROMPT);
				const assistantDetails = res.data?.find(settingsData => settingsData.key == SettingString.GPT_KB_DRAFT_ASSISTANT_RULES);

				if (businessDetails?.value != null) {
					setBusinessDetails(businessDetails.value);
				}

				if (assistantDetails?.value != null) {
					setAssistantGuidelines(assistantDetails?.value);
				}

				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		}

		getGptValues();
	}, []);

	async function onSubmit() {
		const settings: Setting[] = [
			{ key: SettingString.GPT_COMPANY_PROMPT, value: businessDetails },
			{ key: SettingString.GPT_KB_DRAFT_ASSISTANT_RULES, value: assistantGuidelines }
		];

		const res = await ApiUpdateSettings(settings);

		if (res.data != null && res.successful) {
			toast.success("Save successful");
			await refreshCache();
		} else {
			toast.error("Could not save AI settings");
		}
	}

	return (
		<FormParent
			title="Knowledge Base assistant"
			description="Manage your AI assistant by providing up-to-date information about your business"
		>
			<FormSection>
				{/* <div className="flex flex-row justify-between my-4">
					<div className="font-semibold text-lg">
						Watch our simple setup guide
					</div>
					<div className="rounded-full border border-[#CBD7E7] bg-[#EFF5FC] text-[#2C6CC4] px-2 py-1 flex items-center gap-1">
						<LuClock3 /> 3 Min
					</div>
				</div>*/}

				<TextArea
					dataname=""
					handleSubmit={(_key, newValue) => setBusinessDetails(newValue)}
					label="Business details"
					toolTip="Tell us about your business. Its name, products, and your customer profile."
					placeholder="Acme Corp. is a company specialising in the production of novelty items for use in cartoons.
Among its prestigious clients are the Road Runner and Wile E. Coyote.
Its main lines of products are:
					- Anvils
					- Exploding tennis balls
					- Instant tunnels
					"
					value={businessDetails}
					maxLength={1500}
					rows={8}
				/>
				<TextArea
					dataname=""
					handleSubmit={(_key, newValue) => {setAssistantGuidelines(newValue);}}
					label= "Assistant guidelines"
					toolTip="Tell your AI assistant how you would like it to behave."
					placeholder="Be helpful, clear and descriptive. Do not use unprofessional language."
					value={assistantGuidelines}
					maxLength={1500}
					rows={8}
				/>
			</FormSection>

			<div className="flex items-center ml-4 mb-4">
				<Button className="" onClick={() => {}} label="Cancel" />
				<input
					type="submit"
					className="ml-2 btn-blue"
					value="Update"
					disabled={isLoading}
					onClick={onSubmit}
				/>
			</div>
		</FormParent>
	);
}
