
export interface SalablePlan {
	plan: BillingPlan;
	agentCount: number;
	paymentTerm: BillingPaymentTerm;
	currency: BillingCurrency;
}

export interface BillingStatus {
	salablePlan: SalablePlan;
	cancelled?: boolean;
	nextPaymentDate?: Date;
	subscriptionUuid?: string;
}

export enum BillingPaymentTerm {
	MONTHLY,
	YEARLY
}

export enum BillingPlan {
	TRIAL,
	APPSUMO,
	BASIC,
	PRO
}

export enum BillingCurrency {
	USD,
	GBP,
	EUR
}

export function getBillingPaymentTermLabel(term: BillingPaymentTerm): string {
	switch (term) {
		case BillingPaymentTerm.MONTHLY: return "Monthly";
		case BillingPaymentTerm.YEARLY: return "Yearly";
	}
}

export function getBillingPlanLabel(plan: BillingPlan): string {
	switch (plan) {
		case BillingPlan.TRIAL: return "Trial";
		case BillingPlan.APPSUMO: return "Appsumo";
		case BillingPlan.BASIC: return "Basic";
		case BillingPlan.PRO: return "Pro";
	}
}

export function getCurrencySymbol(currency: BillingCurrency): string {
	switch (currency) {
		case BillingCurrency.USD: return "$";
		case BillingCurrency.GBP: return "£";
		case BillingCurrency.EUR: return "€";
	}
}

export function getPlanPrice(plan: BillingPlan, currency: BillingCurrency, term: BillingPaymentTerm): number | undefined { // Can't figure out how to make this exhaustive!
	if (plan == BillingPlan.BASIC) {
		if (currency == BillingCurrency.USD) {
			if (term == BillingPaymentTerm.MONTHLY) {
				return 20;
			} else if (term == BillingPaymentTerm.YEARLY) {
				return 192;
			}
		} else if (currency == BillingCurrency.GBP) {
			if (term == BillingPaymentTerm.MONTHLY) {
				return 15;
			} else if (term == BillingPaymentTerm.YEARLY) {
				return 144;
			}
		} else if (currency == BillingCurrency.EUR) {
			if (term == BillingPaymentTerm.MONTHLY) {
				return 15;
			} else if (term == BillingPaymentTerm.YEARLY) {
				return 144;
			}
		}

	} else if (plan == BillingPlan.PRO) {
		if (currency == BillingCurrency.USD) {
			if (term == BillingPaymentTerm.MONTHLY) {
				return 40;
			} else if (term == BillingPaymentTerm.YEARLY) {
				return 384;
			}
		} else if (currency == BillingCurrency.GBP) {
			if (term == BillingPaymentTerm.MONTHLY) {
				return 30;
			} else if (term == BillingPaymentTerm.YEARLY) {
				return 288;
			}
		} else if (currency == BillingCurrency.EUR) {
			if (term == BillingPaymentTerm.MONTHLY) {
				return 30;
			} else if (term == BillingPaymentTerm.YEARLY) {
				return 288;
			}
		}
	} else if (plan == BillingPlan.TRIAL) {
		return 0;
	} else if (plan == BillingPlan.APPSUMO) {
		return 0;
	}
}
