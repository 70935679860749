import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Agent } from "@/Api/genApi.schemas";
import { useUpdateAgents } from "@/Api/genApi";
import { SaveBar } from "@/Fragments/EntityViewer/SaveBar";
import { FormHotkeys } from "@shared/Components/Utils/FormHotkeys";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";

interface AgentViewerNewFormProps {
	defaultData: Agent;
}

export function AgentViewerNewForm(props: AgentViewerNewFormProps) {
	const navigate = useNavigate();

	const updateAgentReq = useUpdateAgents();

	const { control, handleSubmit, reset, setValue, getValues, setError, watch, formState } = useForm<Agent>({ defaultValues: props.defaultData });

	async function formSubmit(data: Agent) {
		// if (data.id > 0) {
		// 	const updateSuccess = await updateEntity(data);

		// 	if (updateSuccess) {
		// 		setEditmode(false);
		// 		refreshLoggedInAgent();
		// 	}
		// } else {
		// 	const newId = await createEntity(data);

		// 	if (newId != null && newId > -1) {
		// 		setEditmode(false);
		// 		navigate("/config/manage/agents/" + newId);
		// 	}
		// }
	}

	return (
		<>
			<FormHotkeys onSubmit={handleSubmit(formSubmit)} onCancel={() => {}} />

			<div className="overflow-auto">
				<FormParent title={props.defaultData.name}>

					{/* <FormError error={formError} /> */}

					<FormSection title="Details">
						<W_TextInput
							control={control}
							dataname="name"
							label="Name"
							mandatory
							autoFocus
						/>
						<W_TextInput
							control={control}
							dataname="email"
							label="Email"
							mandatory
							type={InputTypes.Email}
							autocomplete="off"
						/>

						<W_TextInput control={control} dataname="jobTitle" label="Job Title" />
						<W_TextInput control={control} dataname="phoneNumber" label="Phone number" type={InputTypes.Tel} />
					</FormSection>
				</FormParent>
			</div>

			<SaveBar formState={formState} onSave={() => handleSubmit(formSubmit)()} onCancel={() => {}} />
		</>
	);
}
