import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PriorityGrid } from "@/Pages/Config/SLA/SlaPriorityGrid";
import { Button, ButtonRed } from "../../../Components/Button/Button";
import OtherFunctions from "../../../Helpers/OtherFunctions";
import DeleteWarningModal from "../../../Components/Utility/Modals/DeleteWarningModal";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { Sla } from "@shared/Entities/EntityTypes";

interface SlaViewerProps {
	id: number;
	data?: Sla;
	createNew(data: Sla): void;
	updateData(data: Sla): void;
	delete(): Promise<void>;
}

export default function SlaViewer(props: SlaViewerProps) {
	const [internalData, setInternalData] = useState<Sla | undefined>(props.data);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const navigate = useNavigate();
	const { handleSubmit } = useForm();

	useEffect(() => {
		// Override internal data when data changes in props.
		setInternalData(props.data);
	}, [props.data]);

	function handleChangeInFormComponent(key: string, newValue: any) {
		const dataCopy = Object.assign({}, internalData);
		const newData = OtherFunctions.SafeChangeProperty(dataCopy, key, newValue);

		setInternalData(newData);
	}

	function cancelButtonClick() {
		navigate("/config/manage/slas");
	}

	async function deleteSla() {
		await props.delete();
		navigate("/config/manage/slas");
	}

	function formSubmit() {
		if (internalData != null) {
			if (internalData.id > 0) {
				props.updateData(internalData);
			} else {
				props.createNew(internalData);
			}
		}
	}

	return (
		props.data != null ?
			<form onSubmit={handleSubmit(formSubmit)} className="max-w-xl">
				<div style={{ padding: "5px 15px" }}>
					<TextInput
						value={props.data.name}
						dataname="name"
						handleSubmit={handleChangeInFormComponent}
						label="Name"
						mandatory
						autoFocus={props.id < 0}
					/>
				</div>

				<PriorityGrid data={internalData} handleChange={handleChangeInFormComponent} />

				<div className="flex items-center">
					{props.id > 0 ?
						<>
							<Button className="ml-4" onClick={cancelButtonClick} label="Cancel" />
							<input type="submit" className="ml-2 btn-blue" value="Update" />
							<ButtonRed className="ml-2" label="Delete" onClick={() => setDeleteModalOpen(true)} />
						</>
						:
						<>
							<Button className="ml-4" onClick={cancelButtonClick} label="Cancel" />
							<input type="submit" className="ml-2 btn-blue" value="Create" />
						</>}
				</div>

				<DeleteWarningModal isOpen={deleteModalOpen} onDelete={deleteSla} onCancel={() => setDeleteModalOpen(false)} message="This will remove this SLA from any tickets using it, leaving them without an SLA." />
			</form>
			:
			null
	);
}
