import { useEffect } from "react";

export function ApiDocs() {
	document.title = "Api Docs | GoDesk";

	const openApiUrl = process.env.NODE_ENV == "development" ? `http://${window.location.hostname}:8080/v3/api-docs` : "/backend/v3/api-docs";

	const redocHtml = `
		<redoc spec-url="${openApiUrl}" path-in-middle-panel=true></redoc>
		<script src="https://cdn.redoc.ly/redoc/latest/bundles/redoc.standalone.js"> </script>
	`;

	useEffect(() => {
		const script = document.createElement("script");

		script.src = "https://cdn.redoc.ly/redoc/latest/bundles/redoc.standalone.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
		  document.body.removeChild(script);
		};
	  }, []);


	return (
		<div
			dangerouslySetInnerHTML={{ __html: redocHtml }}
		/>
	);
}
