import { QuestionTooltip } from "@shared/Components/QuestionTooltip/QuestionTooltip";
import { lengthMinsToString } from "@shared/Helpers/DateFunctions";

interface StatBoxProps {
	name: string;
	value?: number;
	valueTimeMins?: number;
	tooltip?: React.ReactNode;
	/** Value percentage in decimal. */
	valuePerc?: number;
}

export function StatBox(props: StatBoxProps) {
	// Value with commas.
	let displayValue = props.value != null ? props.value.toLocaleString() : "-";

	// Override value if it's a time.
	if (props.valueTimeMins != null) {
		if (props.valueTimeMins == 0) {
			displayValue = "-";
		} else {
			displayValue = lengthMinsToString(props.valueTimeMins);
		}
	}

	// Override value if it's a percentage.
	if (props.valuePerc != null) {
		if (props.valuePerc > -1) {
			const correctPerc = props.valuePerc * 100;
			const correctFormat = Math.floor(correctPerc);

			displayValue = correctFormat + "%";
		} else {
			displayValue = "-";
		}
	}

	return (
		<div className="bg-white mr-5 mb-5 rounded border border-gray-300 w-52 shrink-0 p-3 lg:p-3 text-center flex flex-col">
			<div className="self-end h-5 w-5">
				{props.tooltip &&
					<QuestionTooltip tip={props.tooltip} />}
			</div>

			<div className="text-gray-600 h-1/4">
				{props.name}
			</div>
			<div className="text-slate-500 text-lg lg:text-2xl mt-3 py-1">
				{displayValue}
			</div>
		</div>
	);
}
