import { Link } from "react-router-dom";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import toast from "react-hot-toast";
import { DateTime } from "luxon";
import { MdOutlineMail } from "react-icons/md";
import Grid from "@mui/material/Unstable_Grid2";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { BadgeColor } from "@/Components/Badge/BadgeColor";
import { LoadingPrimaryButton, PrimaryButton } from "@/Components/Button/PrimaryButton";
import { LoadingNeutralButton, NeutralButton } from "@/Components/Button/NeutralButton";
import { DangerButton, LoadingDangerButton } from "@/Components/Button/DangerButton";
import { Button, ButtonBlue, ButtonRed } from "../../Components/Button/Button";
import PaginationDisplay from "../../Components/EntityTable/PaginationDisplay";
import CacheDropdown from "../../Components/FormComponents/CacheDropdown/CacheDropdown";
import InlineText from "../../Components/FormComponents/InlineText/InlineText";
import MultiEntitySelect from "../../Components/FormComponents/MultiEntitySelect/MultiEntitySelect";
import { Icons } from "./DesignIcons";
import { Badge } from "../../Components/Badge/Badge";
import { SlaBadge } from "../../Components/EntityTable/SlaBadge";
import { Tickbox } from "@shared/Components/FormComponents/Tickbox/Tickbox";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";
import { InfoToast } from "@shared/Components/Utils/Toasts";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";

export default function Design() {
	document.title = "Design | GoDesk";

	const dateIn2Hrs = DateTime.now().plus({ hours: 2 }).toISO();
	const dateIn24Mins = DateTime.now().plus({ minutes: 24 }).toISO();

	const date53MinsAgo = DateTime.now().minus({ minutes: 53 }).toISO();

	return (
		<div className="container mx-auto p-8">
			<h1 className="text-5xl mb-4">GoDesk Design System - <Link to="/" className="text-blue-700">Home</Link> - <Link to="/design/form-components" className="text-blue-700">Form components</Link></h1>

			<div className="grid grid-cols-2 gap-10">
				<div>
					<div className="mb-8">
						<h2 className="text-2xl mb-2">Fonts</h2>
						<div>
							Inter
						</div>
					</div>

					<div className="mb-8">
						<h2 className="text-2xl mb-2">Colour</h2>
						<Colours />
					</div>

					<div className="mb-8">
						<h2 className="text-2xl mb-2">Buttons</h2>

						<ButtonBlue className="mr-3" label="Primary" />
						<Button className="mr-3" icon={<AiOutlineHome />} label="Icon" />
						<Button className="mr-3" icon={<AiOutlineHome />} />
						<Button className="mr-3" label="Secondary" />
						<ButtonRed className="mr-3" label="Red Button" />
						<ButtonRed className="mr-3" label="Disabled" disabled />
						<Button className="mr-3" label="loading" loading />
						<Button className="mr-3" label="loading and disabled" loading disabled />

						<a href="#">Link</a>
					</div>

					<div className="mb-8">
						<h2 className="text-2xl mb-2">MUI Buttons</h2>
						<Buttons />
					</div>

					<div className="mb-8">
						<h2 className="text-2xl mb-2">Badges</h2>

						<Badge color={BadgeColor.GREY} label="Grey" />
						<Badge color={BadgeColor.GREEN} label="Green" />
					</div>

					<div className="mb-8">
						<h2 className="text-2xl mb-2">SLA Badges</h2>


						<div className="space-x-4">
							<SlaBadge responseDueISO={dateIn2Hrs} />
							<SlaBadge responseDueISO={dateIn24Mins} />
							<SlaBadge paused />
							<SlaBadge responseDueISO={date53MinsAgo} />
						</div>
					</div>

					<div className="mb-8">
						<h2 className="text-2xl mb-2">Pagination</h2>
						<Pagination />
					</div>

					<div className="mb-8">
						<h2 className="text-2xl">Dropdowns</h2>
						<Dropdowns />
					</div>

					<div className="mb-8">
						<h2 className="text-2xl">Alerts</h2>
						<AlertBox alertType="info">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
						</AlertBox>
						<AlertBox alertType="warning">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex.
						</AlertBox>
						<AlertBox alertType="error">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</AlertBox>
					</div>
				</div>

				<div>
					<div className="mb-8">
						<h2 className="text-2xl">Forms Components</h2>
						<FormComponents />
					</div>
					<div className="mb-8">
						<h2 className="text-2xl">Icon and Text</h2>
						<div className="flex items-center" style={{ fontSize: "30px", marginBottom: "20px" }}><AiOutlineUser />text</div>
					</div>
					<div className="mb-8">
						<h2 className="text-2xl">Toasts</h2>
						<Button label="Info" onClick={() => toast("This is an info msg.", InfoToast)} className="mr-3" />
						<Button label="Success" onClick={() => toast.success("This is a success msg!")} className="mr-3" />
						<Button label="Error" onClick={() => toast.error("This is an error.")} />
					</div>
					<div className="mb-8">
						<h2 className="text-2xl">Icons</h2>

						<p>Prefer outlined to filled. Prefer <Link to="https://react-icons.github.io/react-icons/icons/ai/" target="_blank" className="text-blue-700">Ant Design icons</Link> (Ai in react-icons). But don't stress too much about using ones from the same icon pack.</p>

						<div className="text-2xl">
							<Icons />
						</div>
					</div>
					<div>
						<h2 className="text-2xl">Guidelines</h2>
						<div>
							<Guidelines />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function Buttons() {
	return (
		<Grid container spacing={3}>
			<Grid xs={3}>
				<PrimaryButton>
					Button
				</PrimaryButton>
			</Grid>
			<Grid xs={3}>
				<PrimaryButton disabled>
					Disabled
				</PrimaryButton>
			</Grid>
			<Grid xs={3}>
				<PrimaryButton
					startIcon={<MdOutlineMail />}
				>
					Icon
				</PrimaryButton>
			</Grid>
			<Grid xs={3}>
				<LoadingPrimaryButton
					startIcon={<MdOutlineMail />}
					loading={true}
				>
					Loading
				</LoadingPrimaryButton>
			</Grid>

			<Grid xs={3}>
				<NeutralButton>
					Button
				</NeutralButton>
			</Grid>
			<Grid xs={3}>
				<NeutralButton disabled>
					Disabled
				</NeutralButton>
			</Grid>
			<Grid xs={3}>
				<NeutralButton
					startIcon={<MdOutlineMail />}
				>
					Icon
				</NeutralButton>
			</Grid>
			<Grid xs={3}>
				<LoadingNeutralButton
					startIcon={<MdOutlineMail />}
					loading={true}
				>
					Loading
				</LoadingNeutralButton>
			</Grid>

			<Grid xs={3}>
				<DangerButton>
					Button
				</DangerButton>
			</Grid>
			<Grid xs={3}>
				<DangerButton disabled>
					Disabled
				</DangerButton>
			</Grid>
			<Grid xs={3}>
				<DangerButton
					startIcon={<MdOutlineMail />}
				>
					Icon
				</DangerButton>
			</Grid>
			<Grid xs={3}>
				<LoadingDangerButton
					startIcon={<MdOutlineMail />}
					loading={true}
				>
					Loading
				</LoadingDangerButton>
			</Grid>
		</Grid>
	);
}

function Colours() {
	return (
		<>
			<ColourSquare title="Brand Main" colorCss="bg-blue-500" />
			<ColourSquare title="Brand Tint" colorHex="#E1F2FE" />
			<ColourSquare title="Text" colorHex="#241C15" />
		</>
	);
}

interface ColourSquareProps {
	colorCss?: string;
	colorHex?: string;
	title?: string;
}

function ColourSquare(props: ColourSquareProps) {
	return (
		<div className="inline-block mr-4">
			<div>
				{props.title}
			</div>
			<div className={"w-28 h-28 border-gray-500 border-solid border " + props.colorCss} style={{ backgroundColor: props.colorHex }} ></div>
			<div>
				{props.colorCss ?? props.colorHex}
			</div>
		</div>
	);
}

function Pagination() {
	function nope() {}

	return (
		<PaginationDisplay pagination={{ total: 95, offset: 0, limit: 30 }} nextPage={nope} prevPage={nope} />
	);
}

function FormComponents() {
	return (
		<>
			<TextInput dataname="" label="TextInput" placeholder="you@example.com" handleSubmit={() => {}} />

			InlineText
			<br />
			<InlineText dataname="" handleSubmit={() => {}} value="Default value" />
			<br />

			<Tickbox valueName="" label="Tickbox" onChange={() => {}} />
		</>
	);
}

function Dropdowns() {

	return (
		<>
			<div className="grid grid-cols-2 gap-4">
				<div>
					<CacheDropdown dataname="" label="Agents" onChange={() => {}} cacheSlice={CacheSlices.Agents} />
					<CacheDropdown dataname="" label="Priorities" onChange={() => {}} cacheSlice={CacheSlices.Priorities} />
				</div>
				<div>
					<CacheDropdown dataname="" label="Teams" onChange={() => {}} cacheSlice={CacheSlices.Teams} />
					<CacheDropdown dataname="" label="Users" onChange={() => {}} cacheSlice={CacheSlices.Users} />
				</div>
			</div>

			<MultiEntitySelect dataname="" label="MultiEntitySelect (Users)" handleSubmit={() => {}} cacheSlice={CacheSlices.Users} />
		</>
	);
}

function Guidelines() {

	return (
		<>
			<p>
				<div className="text-lg font-medium">Copy</div>
				<ul className="list-disc">
					<li>Tone should be polite and professional. Copy should be as simple and consise as possible.</li>
					<li>Do not use exclaimation marks!</li>
					<li>Do not capitalise entities in a sentence - 'ticket' not 'Ticket'</li>
					<li>Use 'example.com' and '[name]@example.com' for placeholders.</li>
					<li>Do not use fullstops at the end of tooltips 'Click here'.</li>
					<li>Do not use '(s)' for potential plurals where we could work it out properly.</li>
				</ul>
			</p>

			<p>
				<div className="text-lg font-medium">Capitals</div>
				<ul className="list-disc">
					<li>Page / modal titles - <Link to="https://titlecaseconverter.com/" target="_blank" className="text-blue-700">title case</Link>.</li>
					<li>Subtitles within a page - title case.</li>
					<li>Buttons - Just capitalise first word.</li>
					<li>Form control labels - Just capitalise first word.</li>
				</ul>
			</p>

			<p>
				<div className="text-lg font-medium">Alignment</div>
				<ul className="list-disc">
					<li>Put the primary button on the right.</li>
					<li>Align buttons to the right on a modal.</li>
				</ul>
			</p>
		</>
	);
}
