import { useEffect, useState } from "react";
import { useCache } from "@/Hooks/useCache";
import { ButtonBlue } from "@/Components/Button/Button";
import { ChangePlanModal } from "@/Pages/Config/Account/ChangePlanModal";
import { getBillingPaymentTermLabel, getBillingPlanLabel, BillingStatus } from "@shared/Models/BillingObj";
import { SettingInt } from "@shared/Enums/SettingEnums";

export default function Account() {
	const { cache } = useCache();

	const appsumoLicCount = cache.getSettingInt(SettingInt.APPSUMO_LIC_COUNT);

	const [billingStatus, setBillingStatus] = useState<BillingStatus>();

	const [editPlanModalOpen, setEditPlanModalOpen] = useState(false);

	useEffect(() => {
		fetch();

		async function fetch() {
			// const licenceRes = await ApiGetBillingStatus();

			// if (licenceRes.successful && licenceRes.data != null) {
			// 	setBillingStatus(licenceRes.data);
			// } else {
			// 	toast.error("Could not fetch licence.");
			// 	return;
			// }
		}
	}, []);

	return (
		<div className="p-5">
			<div className="text-2xl">
				Account
			</div>

			<div className="flex flex-wrap my-5 gap-40">
				{/*
				<div className='p-3 w-2/5  bg-white rounded-lg border border-gray-100 shadow '>
					<CacheDropdown label="Primary Contact" toolTip="The account owner is the agent who we will contact about any issues with your account. This agent must have the 'Account Administrator' permission." dataname="" onChange={() => {}} cacheSlice={CacheSlices.Agents} />

					<div className="grid grid-cols-2 my-3 gap-1.5">
						<div className="text-slate-700">Email address</div>
						<div className="font-semibold">{agent.email}</div>

						<div className="text-slate-700">Phone number</div>
						<div className="font-semibold">{agent.phoneNumber ?? "-"}</div>
					</div>
				</div>
				*/}

				<div className="p-3 grid grid-cols-2 gap-1.5 bg-white rounded-lg border border-gray-100 shadow w-96">
					<div style={{ fontSize: "20px", margin: "10px 0" }}>Plan</div>
					<div></div>

					<div className="text-slate-700">Agent licences</div>
					<div className="font-semibold">{billingStatus?.salablePlan.agentCount ?? "-"}</div>

					<div className="text-slate-700">Plan name</div>
					<div className="font-semibold">{billingStatus != null ? getBillingPlanLabel(billingStatus.salablePlan.plan) : "-"}</div>

					<div className="text-slate-700">Billing period</div>
					<div className="font-semibold">{billingStatus != null ? getBillingPaymentTermLabel(billingStatus.salablePlan.paymentTerm) : "-"}</div>

					{appsumoLicCount != null && appsumoLicCount > 0 &&
						<>
							<div className="text-slate-700">AppSumo licences</div>
							<div className="font-semibold">{appsumoLicCount * 3}</div>
						</>}

					<div>
						<ButtonBlue label="Edit Plan" onClick={() => setEditPlanModalOpen(true)} />
					</div>
				</div>
			</div>

			<p className="text-xl">Plans</p>

			<div className="flex space-x-5">
				<Plan
					name="Basic"
					subtitle="Best option for personal use & for your next project."
					price="£15"
				/>

				<Plan
					name="Pro"
					subtitle="Best option for personal use & for your next project."
					price="£30"
				/>
			</div>

			<ChangePlanModal isOpen={editPlanModalOpen} closeModal={() => setEditPlanModalOpen(false)} />
		</div>
	);
}

interface PlanProps {
	name: string;
	subtitle: string;
	price: string;
}

function Plan(props: PlanProps) {
	return (
		<div className="flex flex-col p-6 text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
			<h3 className="mb-4 text-2xl font-semibold">{props.name}</h3>
			<p className="font-light text-gray-500 dark:text-gray-400">{props.subtitle}</p>
			<div className="flex justify-center items-baseline my-8">
				<span className="mr-2 text-5xl font-extrabold">{props.price}</span>
				<span className="text-gray-500 dark:text-gray-400">/ agent / month</span>
			</div>
		</div>
	);
}
