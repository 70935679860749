import React, { CSSProperties, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import CloseConfirmationModal from "@shared/Components/CloseConfirmationModal";
import { Hotkeys } from "@shared/lib/Hotkeys";

export interface ModalBaseProps {
	isOpen: boolean;
	/** Called when the user closes the modal. */
	onClose(): void;
	children: React.ReactNode;
	/** CSS classes for the modal. */
	className?: string;
	/** CSS properties for the modal that override the defaults. */
	modalStyle?: CSSProperties;
	/** CSS properties for the modal's background that override the defaults. */
	overlayStyle?: CSSProperties;
	/** Removes the open/close animation. */
	suppressAnimation?: boolean;
	/** This will show a second modal only if you close the modal by clicking outside of the modal. */
	showCloseWarning?: boolean | (() => boolean);
}

/**
 * This has the basic styles and animation for modals. Don't use this component directly, use Modal instead.
 */
export default function ModalBase(props: ModalBaseProps) {
	const [closeConfirmationModalIsOpen, setCloseConfirmationModalIsOpen] = useState(false);

	/** This method will break if we try to use two of the same modals at the same time i.e. 2 NewEntityModals at the same time. */
	const overlayDivId = useRef(Math.random().toString(36).substring(2));

	const duration = 0.15;
	const animated = props.suppressAnimation ? false : true;

	const overlayAnimation = animated ? {
		initial: { opacity: 0 },
		animate: { opacity: 1 },
		exit: { opacity: 0 },
		transition: { duration: duration },
	} : {};

	const modalAnimation = animated ? {
		initial: { opacity: 0, marginTop: "20px" },
		animate: { opacity: 1, marginTop: "0" },
		exit: { opacity: 0, marginTop: "20px" },
		transition: { duration: duration },
	} : {};

	function onClickOverlay(e: React.MouseEvent<HTMLDivElement>) {
		const clickedOnId = (e.target as HTMLDivElement).id;

		if (overlayDivId.current == clickedOnId) {
			doClose();
		}
	}

	function doClose() {
		const showCloseWarning = props.showCloseWarning;

		if (showCloseWarning != null) {
			if (typeof showCloseWarning == "boolean") {
				if (showCloseWarning) {
					setCloseConfirmationModalIsOpen(true);
					return;
				}
			} else if (showCloseWarning()) {
				setCloseConfirmationModalIsOpen(true);
				return;
			}
		}

		props.onClose();
	}

	return (
		<AnimatePresence mode="wait" initial={true}>
			{props.isOpen && (
				<motion.div
					key="overlay"
					id={overlayDivId.current}
					onClick={onClickOverlay}
					style={{ ...overlayDefault, ...props.overlayStyle }}
					{...overlayAnimation}
				>
					<motion.div
						key="modal"
						className={`godesk-modal ${props.className}`} // 'godesk-modal' is used by <Hotkeys />.
						style={{ ...modalDefault, ...props.modalStyle }}

						{...modalAnimation}
					>
						<Hotkeys hotkeys="Escape" callback={doClose} />

						{props.children}

						<CloseConfirmationModal
							isOpen={closeConfirmationModalIsOpen}
							onCancel={() => setCloseConfirmationModalIsOpen(false)}
							onClose={() => {
								setCloseConfirmationModalIsOpen(false);
								props.onClose();
							}}
						/>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
}

const overlayDefault: CSSProperties = {
	zIndex: 50,
	backgroundColor: "rgba(0, 0, 0, 0.1)",
	backdropFilter: "blur(3px)",
	top: 0,
	left: 0,
	width: "100%",
	height: "100%",
	position: "fixed",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};

const modalDefault: CSSProperties = {
	background: "white",
	position: "relative",
	maxWidth: "90%",
	maxHeight: "90%",
	display: "flex",
	flexDirection: "column",
	borderRadius: "8px",
	borderStyle: "solid",
	borderWidth: "1px",
	borderColor: "#cdd7e1",
	boxShadow: "0px 0px 11px 1px rgba(0,0,0,0.18)"
};
