import { TicketAgentSection } from "./TicketAgentSection";
import { TicketDetailsSection } from "./TicketDetailsSection";
import { TicketUserSection } from "./TicketUserSection";
import { TicketEmailCCSection } from "./TicketEmailCCSection";
import { Ticket } from "@shared/Entities/EntityTypes";

interface TicketDetailsProps {
	ticket: Ticket;
	handleSubmit(key: string, newValue: any): void;
}

export function TicketDetails(props: TicketDetailsProps) {
	return (
		<div>
			<TicketUserSection ticket={props.ticket} handleSubmit={props.handleSubmit} />

			<TicketEmailCCSection ticket={props.ticket} handleSubmit={props.handleSubmit} />

			<TicketAgentSection ticket={props.ticket} handleSubmit={props.handleSubmit} />

			<TicketDetailsSection ticket={props.ticket} handleSubmit={props.handleSubmit} />
		</div>
	);
}
