import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useAppSelector } from "@/Store/hooks";
import { cacheUpdated } from "@/Store/Reducers/cache/cacheSlice";
import { EnrichedCache, AppCache } from "@/Store/Reducers/cache/CacheTypes";
import api from "@/Api/Api";
import { SettingBool, SettingInt, SettingString } from "@shared/Enums/SettingEnums";

export function useCache() {
	const cacheFromRedux = useAppSelector(state => state.cache);
	const dispatch = useDispatch();

	const [cache, setCache] = useState<EnrichedCache>(enrichCache(cacheFromRedux));

	useEffect(() => {
		setCache(enrichCache(cacheFromRedux));
	}, [cacheFromRedux]);

	async function refreshCache() {
		const result = await api.BasicGet<AppCache>("/cache", { "new": "true" });

		if (result.successful && result.data != null) {
			dispatch(cacheUpdated(result.data));
		} else {
			toast.error("Could not refresh the cache.");
		}
	}

	return {
		cache: cache,
		refreshCache: refreshCache
	};
}

function enrichCache(cache: AppCache): EnrichedCache {
	return {
		...cache,

		getAgent(id?: number) { return id != null ? this.Agents.find(entity => entity.id == id) : undefined; },
		getCategory(id?: number) { return id != null ? this.Categories.find(entity => entity.id == id) : undefined; },
		getChannel(id?: number) { return id != null ? this.Channels.find(entity => entity.id == id) : undefined; },
		getCompany(id?: number) { return id != null ? this.Companies.find(entity => entity.id == id) : undefined; },
		getCustomField(id?: number) { return id != null ? this.CustomFields.find(entity => entity.id == id) : undefined; },
		getKb(id?: number) { return id != null ? this.Kbs.find(entity => entity.id == id) : undefined; },
		getKbFolder(id?: number) { return id != null ? this.KbSections.find(entity => entity.id == id) : undefined; },
		getLayout(id?: number) { return id != null ? this.Layouts.find(entity => entity.id == id) : undefined; },
		getPriority(id?: number) { return id != null ? this.Priorities.find(entity => entity.id == id) : undefined; },
		getSla(id?: number) { return id != null ? this.Slas.find(entity => entity.id == id) : undefined; },
		getStatus(id?: number) { return id != null ? this.Statuses.find(entity => entity.id == id) : undefined; },
		getTeam(id?: number) { return id != null ? this.Teams.find(entity => entity.id == id) : undefined; },
		getUser(id?: number) { return id != null ? this.Users.find(entity => entity.id == id) : undefined; },
		getView(id?: string | number) { return id != null ? this.Views.find(entity => entity.id == id) : undefined; },

		getSettingValue(key: string): string | undefined | null {
			// Enable all plugins in dev mode.
			if ((key.includes("plugin") && key.includes("enabled")) && (process.env.NODE_ENV == "development" || window.location.hostname == "localhost")) {
				return "1";
			}

			return this.Settings.find(setting => setting.key == key)?.value;
		},

		getSettingString(key: SettingString): string | null {
			return this.getSettingValue(key) ?? null;
		},
		getSettingInt(key: SettingInt): number | null {
			const value = this.getSettingValue(key);

			return value != null ? parseInt(value) : null;
		},
		getSettingBool(key: SettingBool): boolean | null {
			const value = this.getSettingValue(key);

			if (value == "1") {
				return true;
			} if (value == "0") {
				return false;
			} else {
				return null;
			}
		},

		getTenantId(): string {
			return this.getSettingString(SettingString.TENANT_ID) ?? "default";
		},

		getAttachmentsDir(): string {
			return "/attachments/" + this.getTenantId() + "/files/";
		},

		getPicturesDir(): string {
			return "/pictures/" + this.getTenantId() + "/";
		},

		getPortalURL(): string {
			/**
			 * Some clients would like their CNAME to point directly to the portal instead of to the agent app.
			 * Unfortunately, I can't think of a better way to do this then with a hardcoded list of domains.
			 */
			const portalOnlyDomains = ["help.prosperna.com"];

			let defaultDomain = this.getSettingString(SettingString.HELPDESK_DEFAULT_DOMAIN);

			if (defaultDomain == null) {
				return "/support/";
			}

			// Remove port if needed.
			if (defaultDomain.includes(":")) {
				defaultDomain = defaultDomain.split(":")[0];
			}

			const protocol = process.env.NODE_ENV == "development" ? "http" : "https";
			const port = process.env.NODE_ENV == "development" ? ":3001" : "";

			const baseUrl = protocol + "://" + defaultDomain + port;

			const isPortalOnlyDomain = portalOnlyDomains.includes(defaultDomain ?? "");

			if (isPortalOnlyDomain) {
				return baseUrl + "/";
			} else {
				return baseUrl + "/support/";
			}
		}
	};
}
