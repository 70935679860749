import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { AiOutlineSetting } from "react-icons/ai";
import toast from "react-hot-toast";
import { Box } from "@mui/material";
import { ApiUpdateEntity } from "@/legacy/ApiCallerOld";
import { useAgent } from "@/Hooks/useAgent";
import { SideBarButtons } from "@/Components/Sidebar/SideBarButtons";
import { SidebarButton } from "@/Components/Sidebar/SidebarButton";
import { LoggedInAgentSection } from "@/Components/Sidebar/LoggedInAgentSection";
import { useCache } from "@/Hooks/useCache";
import { useAppDispatch } from "../../Store/hooks";
import { agentDetailsChanged } from "../../Store/Reducers/loggedInAgentSlice";
import { Agent } from "@shared/Entities/EntityTypes";
import { Entities } from "@shared/Entities/Entities";

export default function Sidebar() {
	const agent = useAgent();
	const dispatch = useAppDispatch();
	const { refreshCache } = useCache();

	const [expanded, setExpanded] = useState(!agent?.prefs.hideSidebar);

	async function toggleSidebar() {
		const newExpanded = !expanded;

		// Toggle.
		setExpanded(newExpanded);

		// Update + save pref.
		if (agent != null) {
			const updatedAgent = structuredClone(agent);
			updatedAgent.prefs.hideSidebar = !newExpanded;

			const resAgent = await ApiUpdateEntity<Agent>(Entities.AGENT, updatedAgent.id, updatedAgent);

			if (resAgent.successful && resAgent.data != null) {
				dispatch(agentDetailsChanged(resAgent.data));

				await refreshCache();
			} else {
				toast.error("Error. " + resAgent.errorCode + ": " + resAgent.errorMsg);
				return;
			}
		}
	}

	const expandedStyles = expanded ? " w-64 " : " w-[4rem] ";
	const wordmarkStyles = expanded ? " opacity-100" : " opacity-0";

	return (
		<Box
			sx={{ bgcolor: "background.paper" }}
			className={"h-screen p-3 flex flex-col shrink-0 transition-[width] relative border-r border-gray-200 " + expandedStyles}
		>
			<div className="h-14 overflow-hidden">
				<Link to="/home" className="whitespace-nowrap">
					{/* <img src="/logo.png" className="w-11" />*/}
					<img src="/logo.png" className="h-10 object-cover" />
					<img src="/wordmark.png" className={"h-10 object-cover transition-opacity" + wordmarkStyles} />
				</Link>
			</div>
			<div className="flex-grow overflow-hidden">
				<SideBarButtons expanded={expanded} />
			</div>
			<div>
				<button onClick={toggleSidebar} className="bg-white hover:bg-gray-50 hover:shadow-md active:bg-gray-100 border border-gray-400 rounded-full w-7 h-7 shadow  absolute flex items-center justify-center" style={{ right: "calc(-1.5rem / 2)", top: "50%", zIndex: 1 }}>
					{expanded ? <AiOutlineDoubleLeft /> : <AiOutlineDoubleRight />}
				</button>
			</div>
			<div className="">
				<div className="overflow-hidden">
					<SidebarButton
						expanded={expanded}
						title="Configuration"
						link="/config"
						icon={<AiOutlineSetting />}
						tooltipShortcut="Configuration"
					/>
				</div>
				<LoggedInAgentSection expanded={expanded} />
			</div>
		</Box>
	);
}
