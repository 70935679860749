import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { TicketTable } from "@/Components/EntityTable/Tables/TicketTable";
import { UserTable } from "@/Components/EntityTable/Tables/UserTable";
import { useAgent } from "@/Hooks/useAgent";
import { View } from "@shared/Models/View";
import { User } from "@shared/Entities/EntityTypes";

export function SingleUsersPanel() {
	const agent = useAgent();

	const singleUsersView: View = {
		filters: {
			companyId: { type: "number", comparison: "blank", value: -1 },
			isAgent: { type: "bool", comparison: "equals", value: false }
		}
	};

	const singleUsersTicketView: View = {
		filters: {
			companyId: { type: "number", comparison: "blank", value: -1 },
			deleted: { type: "bool", comparison: "equals", value: false }
		}
	};

	if (agent != null && !agent.isAdmin && agent.teams != null) {
		const teamsToInclude = agent.teams.map(agentTeams => agentTeams.teamId);
		teamsToInclude.push(-1); // No team.

		singleUsersTicketView.filters.teamId = { type: "set", comparison: "contains", values: teamsToInclude };
	}

	const defaultUser: User = {
		id: -1
	};

	return (
		<div style={{ height: "calc(100% - 28px)" }} className="p-1">
			<Tabs style={{ height: "100%" }}>
				<div className="px-4 pt-4">
					<TabList>
						<Tab>Users</Tab>
						<Tab>Tickets</Tab>
					</TabList>
				</div>

				<TabPanel style={{ height: "calc(100% - 43px)" }}>
					<UserTable
						view={singleUsersView}
						defaultNewData={defaultUser}
					/>
				</TabPanel>
				<TabPanel style={{ height: "calc(100% - 43px)" }}>
					<TicketTable
						view={singleUsersTicketView}
						sort={{
							sortField: "customCreated",
							sortType: "desc"
						}}
					/>
				</TabPanel>
			</Tabs>
		</div>
	);
}
