import { ReactNode } from "react";
import { getMuiTheme } from "@shared/Style/muiTheme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useAppSelector } from "@/Store/hooks";

interface MuiProviderProps {
	children?: ReactNode;
}

export function MuiProvider(props: MuiProviderProps) {
	const darkModeEnabled = useAppSelector(state => state.app.darkmode);

	const theme = getMuiTheme(darkModeEnabled);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />

			{props.children}
		</ThemeProvider>
	);
}
