
export enum SettingBool {
	USER_TICKET_RECEIPTS_ENABLED = "emailNotifications.userTicketReceiptEnabled",
	HELPDESK_DEMO_DATA_DELETED = "helpdesk.deletedDemoData",

	GPT_INSTRUCTIONS_SAVED = "gpt.instructions.saved"
}

export enum SettingInt {
	LIMITS_AGENTS = "limits.agents",
	LIMITS_CHANNELS = "limits.channels",

	/** The number of Appsumo licences the tenant has. */
	APPSUMO_LIC_COUNT = "account.appsumo.licCount",

	TICKETS_CLOSE_POLICY = "tickets.closePolicy", // TicketClosePolicy. Could be replaced with an enum.
	TICKETS_RETENTION_POLICY = "tickets.retentionPolicy", // TicketRetentionPolicy. Could be replaced with an enum.

	DB_VERSION = "dbversion",
	HELPDESK_DEFAULT_EMAIL_CHANNEL = "helpdesk.defaultemailchannel",
	/** UNIX Timestamp. */
	TRIAL_STARTED = "trialStarted"
}

export enum SettingString {
	TENANT_ID = "tenantid",

	HELPDESK_NAME = "helpdesk.name",
	HELPDESK_DEFAULT_DOMAIN = "helpdesk.defaultdomain",
	HELPDESK_LOGO_PATH = "helpdesk.logo",
	HELPDESK_FAVICON_PATH = "helpdesk.favicon.path",
	HELPDESK_BANNER_PATH = "helpdesk.banner.path",

	GPT_COMPANY_PROMPT = "gpt.prompts.company",
	GPT_KB_DRAFT_ASSISTANT_RULES = "gpt.prompts.kbRules",

	WORKDAY_TIMEZONE = "workday.timezone",
	WORKDAY_MONDAY_START = "workday.mondayStart",
	WORKDAY_MONDAY_FINISH = "workday.mondayFinish",
	WORKDAY_TUESDAY_START = "workday.tuesdayStart",
	WORKDAY_TUESDAY_FINISH = "workday.tuesdayFinish",
	WORKDAY_WEDNESDAY_START = "workday.wednesdayStart",
	WORKDAY_WEDNESDAY_FINISH = "workday.wednesdayFinish",
	WORKDAY_THURSDAY_START = "workday.thursdayStart",
	WORKDAY_THURSDAY_FINISH = "workday.thursdayFinish",
	WORKDAY_FRIDAY_START = "workday.fridayStart",
	WORKDAY_FRIDAY_FINISH = "workday.fridayFinish",
	WORKDAY_SATURDAY_START = "workday.saturdayStart",
	WORKDAY_SATURDAY_FINISH = "workday.saturdayFinish",
	WORKDAY_SUNDAY_START = "workday.sundayStart",
	WORKDAY_SUNDAY_FINISH = "workday.sundayFinish",
}
