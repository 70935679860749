import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { toast } from "react-hot-toast";
import { AiOutlinePaperClip } from "react-icons/ai";
import { TicketFormField } from "@/Components/Utility/Modals/NewTicketModal/ticketFieldEnum";
import { Button } from "@/Components/Button/Button";
import NewTicketField from "@/Components/Utility/Modals/NewTicketModal/NewTicketField";
import { useCache } from "@/Hooks/useCache";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useAppSelector } from "@/Store/hooks";
import NewEntityModal from "@/Components/Utility/Modals/NewEntityModal";
import api from "@/Api/Api";
import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import { Attachment, Ticket } from "@shared/Entities/EntityTypes";
import W_HtmlEditor from "@shared/Components/FormComponents/HtmlEditor/W_HtmlEditor";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { Entities } from "@shared/Entities/Entities";
import { FormError } from "@shared/Components/Form/FormError";
import Key from "@shared/Components/Key/Key";
import Keys from "@shared/Components/Key/Keys";
import { Attachments } from "@shared/Attachments/Attachments";
import { FormHotkeys } from "@shared/Components/Utils/FormHotkeys";
import ModalContent from "@shared/Components/Modal/ModalContent";
import { Tickbox } from "@shared/Components/FormComponents/Tickbox/Tickbox";
import { PreventWindowClose } from "@shared/lib/PreventWindowClose";
import { SettingInt } from "@shared/Enums/SettingEnums";

interface NewTicketModalProps {
	isOpen: boolean;
	onClose(): void;

	defaultNewTicket?: Ticket;

	/** This stops the modal from showing the option to send an email. */
	preventSendAsEmail?: boolean;
}

export default function NewTicketModal(props: NewTicketModalProps) {
	const { cache, refreshCache } = useCache();

	const [activeFields, setActiveFields] = useState<TicketFormField[]>([]);
	const [userModalOpen, setUserModalOpen] = useState(false);
	const [sendAsEmail, setSendAsEmail] = useState(false);
	const [files, setFiles] = useState<Attachment[]>([]);
	const [showAttachmentButton, setShowAttachmentButton] = useState(false);

	const agent = useAppSelector(state => state.loggedInAgent.agent);
	const navigate = useNavigate();

	const generateDefaultValues = useCallback((): Ticket => {
		const defaultChannelId = cache.getSettingInt(SettingInt.HELPDESK_DEFAULT_EMAIL_CHANNEL);

		return {
			id: 0,
			actions: [{ id: 0, ticketId: 0, isPublic: true, noteBody: "" }],
			channelId: defaultChannelId ?? undefined,

			...props.defaultNewTicket
		};
	}, [props.defaultNewTicket]);

	const { control, handleSubmit, reset, formState, setValue, getValues } = useForm<Ticket>({
		defaultValues: generateDefaultValues()
	});

	/**
	 * When we use reset, The default vales are the same because we are calling reset onClose of the modal but the values change from the user viewer.
	 * when props.defaultNewTicket is changed we call this useEffect which updates the old default values to the new values.
	 */
	useEffect(() => {
		reset(generateDefaultValues());
	}, [generateDefaultValues]);

	function toggleButton(field: TicketFormField) {
		const isActive = activeFields.some(af => af == field);

		if (isActive) {
			setActiveFields(activeFields.filter(af => af != field));
		} else {
			setActiveFields([...activeFields, field]);
		}
	}

	function activeButton(field: TicketFormField) {
		return activeFields.some(af => af == field);
	}

	async function onSubmit(ticket: Ticket) {
		ticket.customCreated = DateTime.now().toISO();

		const firstAction = ticket.actions?.[0];

		if (firstAction != null) {
			firstAction.customCreated = DateTime.now().toISO();
			firstAction.agentId = agent?.id;
			firstAction.isPublic = true;
			firstAction.sendToUser = sendAsEmail;

			if (!showAttachmentButton) {
				firstAction.attachments = [];
			}

			firstAction.attachments = files;
		}

		const res = await api.createEntity<Ticket>(Entities.TICKET, ticket, control);

		if (res.successful && res.data != null) {
			toast.success("Ticket created");
			navigate("/tickets/" + res.data.id);
			props.onClose();
			setFiles([]);
			setActiveFields([]);
			setShowAttachmentButton(false);
			reset();
		} else {
			toast.error("Could not create ticket");
		}
	}

	async function newUserCreated(id: number) {
		setUserModalOpen(false);
		await refreshCache();
		setValue("userId", id);
	}

	function close() {
		props.onClose();
		reset();
		setActiveFields([]);
		setShowAttachmentButton(false);
		setSendAsEmail(false);
		setFiles([]);
	}

	/** Show close warning if the description or action note are filled in. */
	function shouldShowCloseWarning() {
		if (getValues("description") != null && getValues("description") != "") {
			return true;
		}

		const actions = getValues("actions");
		if (actions != null && actions[0].noteBody != null && actions[0].noteBody != "") {
			return true;
		}

		return false;
	}

	return (
		<Modal
			className="w-[700px]"
			isOpen={props.isOpen}
			onClose={close}
			showCloseWarning={shouldShowCloseWarning}
			title="New ticket"
		>
			<PreventWindowClose stopClose={shouldShowCloseWarning} />

			<ModalContent widthFull>
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormHotkeys onCancel={props.onClose} onSubmit={handleSubmit(onSubmit)} />
					<div className={"px-4 pb-4"}>
						<FormError formState={formState} />
						<div className="flex flex-row justify-between gap-3">
							<div className="w-2/3 -mt-2">
								<W_TextInput
									autoFocus
									control={control}
									dataname="description"
									label="Subject"
									mandatory
								/>
							</div>
							<div className="w-1/3">
								<div className="flex justify-end -mb-8 cursor-pointer underline text-blue-600" onClick={() => setUserModalOpen(true)}>Add new</div>

								<W_CacheDropdown
									mandatory
									cacheSlice={CacheSlices.Users}
									control={control}
									dataname="userId"
									label="User"
								/>
							</div>
						</div>
						<div className="-mb-2">
							<W_HtmlEditor
								isNewTicketModal
								cannedReplies={cache.CannedReplies}
								control={control}
								dataname="actions[0].noteBody"
								handleCancel={() => props.onClose()}
								handleSubmit={(bodyValue) => {
									// You can't access actions[0].noteBody in setValues you need to do this to get around the issue.
									const actions = getValues("actions");
									if (actions != null && actions[0] != null) {
										actions[0].noteBody = bodyValue ?? "";
									}
									setValue("actions", actions);

									handleSubmit(onSubmit)();
								}}
								heightPx={144}
								label="Body"
							/>
						</div>
					</div>
					{/** This needs a fixed height to stop a weird bug (The buttons move up and down if one is active) */}
					<div className="flex items-center border-t border-b p-4 gap-3 h-[61px] border-[#cdd7e1]">
						<Button active={activeButton(TicketFormField.STATUS)} label="Status" onClick={() => toggleButton(TicketFormField.STATUS)} />
						<Button active={activeButton(TicketFormField.PRIORITY)} label="Priority" onClick={() => toggleButton(TicketFormField.PRIORITY)} />
						<Button active={activeButton(TicketFormField.CATEGORY)} label="Category" onClick={() => toggleButton(TicketFormField.CATEGORY)} />
						<Button active={activeButton(TicketFormField.CC)} label="CC" onClick={() => toggleButton(TicketFormField.CC)} />
						<Button active={activeButton(TicketFormField.BCC)} label="BCC" onClick={() => toggleButton(TicketFormField.BCC)} />
						<Tooltip title="Attach files">
							<div>
								<Button className={showAttachmentButton ? "bg-slate-200" : ""} icon={<AiOutlinePaperClip size="20px" />} onClick={() => setShowAttachmentButton(!showAttachmentButton)} />
							</div>
						</Tooltip>
					</div>

					<div className="grid grid-cols-2 grid-rows-1 gap-x-4 px-4">
						<div>
							<W_CacheDropdown
								mandatory
								cacheSlice={CacheSlices.Teams}
								control={control}
								dataname="teamId"
								label="Team"
							/>
						</div>

						<W_CacheDropdown
							cacheSlice={CacheSlices.Agents}
							control={control}
							dataname="agentId"
							label="Agent"
						/>

						{activeFields.map(field =>
							<NewTicketField control={control} field={field} key={field} />)
						}
					</div>
					{showAttachmentButton &&
						<div className="px-4">
							<Attachments apiCaller={api} attachmentUrl={cache.getAttachmentsDir()} files={files} setFiles={setFiles} />
						</div>}
				</form>
			</ModalContent>

			<ModalButtons>
				<div className="w-full justify-between flex items-center ">
					{!props.preventSendAsEmail &&
						<Tickbox
							label="Send as email"
							value={sendAsEmail}
							onChange={(_key, value) => setSendAsEmail(value)}
							valueName=""
							inline
						/>
					}

					<div className="flex items-center gap-2">
						<Button
							btnClass="btn-grey"
							label="Cancel"
							onClick={() => props.onClose()}
						/>
						<Tooltip title={<Keys keys={[<Key>Ctrl</Key>, "+", <Key>Enter</Key> ]} label={sendAsEmail ? "Create & send" : "Create ticket"} />}>
							<div>
								<Button
									btnClass="btn-blue"
									label={sendAsEmail ? "Create & send" : "Create ticket"}
									onClick={() => handleSubmit(onSubmit)()}
								/>
							</div>
						</Tooltip>
					</div>
				</div>
			</ModalButtons>

			<NewEntityModal
				closeModal={() => setUserModalOpen(false)}
				entityType={Entities.USER}
				isOpen={userModalOpen}
				newCreated={newUserCreated}
			/>
		</Modal>
	);
}
