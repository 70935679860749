import { IoIosCloseCircle, IoIosWarning } from "react-icons/io";
import { AiOutlineLeft, AiOutlineRight, AiOutlineUser, AiOutlineSearch, AiOutlineFolder, AiOutlinePlusCircle, AiOutlineClockCircle } from "react-icons/ai";
import { AiOutlineCloseCircle, AiOutlineMergeCells, AiOutlineHome, AiOutlineClose } from "react-icons/ai";
import { AiOutlineSetting, AiOutlineTeam, AiOutlineDelete } from "react-icons/ai";
import { BiCheckCircle, BiCopyAlt, BiFile, BiKey, BiLinkExternal, BiServer, BiTrash } from "react-icons/bi";
import { MdBusiness, MdCheck, MdArrowBack, MdSettingsBackupRestore } from "react-icons/md";
import { FaCircle, FaMinusCircle, FaDotCircle, FaBoxes, FaCogs, FaUserClock, FaSitemap } from "react-icons/fa";
import { BsChevronBarUp, BsPencil, BsInfoCircleFill } from "react-icons/bs";
import { HiOutlineBookOpen, HiOutlineMail } from "react-icons/hi";
import { GoNote, GoInfo } from "react-icons/go";
import { RiArticleFill, RiArticleLine, RiSpam2Line } from "react-icons/ri";
import { GiTicket } from "react-icons/gi";
import { GrDrag, GrMap, GrView } from "react-icons/gr";

export function Icons() {
	return (
		<>
			<div className="text-base">Entities</div>
			<AiOutlineTeam />
			<AiOutlineUser />
			<GiTicket />
			<MdBusiness />
			<GrView />
			<FaBoxes />
			<BiCopyAlt />
			<FaUserClock />
			<GrMap />
			<FaSitemap />
			<BiServer />

			<div className="text-base">Action Types</div>
			<HiOutlineMail />
			<GoNote />
			<AiOutlineMergeCells />
			<BiCheckCircle />
			<BiTrash />
			<RiSpam2Line />

			<div className="text-base">Symbols</div>
			<AiOutlineLeft />
			<AiOutlineRight />
			<AiOutlineCloseCircle />
			<AiOutlineSetting />
			<AiOutlineDelete />
			<AiOutlineHome />
			<AiOutlineClose />
			<AiOutlineSearch />
			<AiOutlineFolder />
			<AiOutlinePlusCircle />
			<AiOutlineClockCircle />
			<br />
			<IoIosCloseCircle />
			<IoIosWarning />
			<br />
			<BiFile />
			<BsChevronBarUp />
			<BsPencil />
			<BsInfoCircleFill />
			<BiLinkExternal />
			<BiKey />
			<br />
			<GoInfo />
			<br />
			<GrDrag />
			<br />
			<HiOutlineBookOpen />
			<br />
			<MdCheck />
			<MdArrowBack />
			<MdSettingsBackupRestore />
			<br />
			<RiArticleLine />
			<RiArticleFill />
			<br />
			<FaCircle />
			<FaMinusCircle />
			<FaDotCircle />
			<FaCogs />
		</>
	);
}
