import { getAccessToken } from "@/legacy/ApiAuth";
import { ErrorPayload } from "@shared/Api/ApiResponses";

function getApiUrl() {
	if (process.env.NODE_ENV == "development") {
		return `http://${window.location.hostname}:8080`;
	}

	return "/backend";
}

export const customInstance = async <T>({
	url,
	method,
	headers,
	params,
	data,
	signal
}: {
	url: string;
	method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "HEAD";
	headers?: Record<string, any>;
	params?: any;
	data?: any;
	responseType?: string;
	signal?: AbortSignal;
}): Promise<T> => {

	// Get and set our auth token.
	const headersCopy = new Headers(headers);

	if (!headersCopy.has("Authorization")) {
		const authToken = await getAccessToken();

		if (authToken != null) {
			headersCopy.set("Authorization", "Bearer " + authToken);

		}
	}

	const searchParams = params != null ? "?" + new URLSearchParams(params) : "";

	const res = await fetch(
		`${getApiUrl()}${url}` + searchParams,
		{
			method,
			...(data ? { body: JSON.stringify(data), headers: headersCopy } : { headers: headersCopy }),
		}
	)
		.catch(err => {
			throw handleNetworkError(err);
		});

	if (res.ok) {
		const resText = await res.text();

		if (resText == undefined || resText == "") {
			return null as T;
		}

		// Check the server says it's JSON before we try and parse it.
		const contentType = res.headers.get("Content-Type");

		if (contentType != null && contentType.includes("application/json")) {
			return JSON.parse(resText);
		} else {
			return resText as T;
		}
	} else {
		throw await handleGoDeskError(res);
	}
};

async function handleGoDeskError(res: Response): Promise<ErrorPayload> {
	const resText = await res.text();

	if (res.status == 400 || res.status == 404 || res.status == 500) {
		const resJson = resText != undefined && resText != "" ? JSON.parse(resText) : null;

		return {
			errorMsg: resJson?.errorMsg,
			errorField: resJson?.errorField,
			formFieldErrorCode: resJson?.formFieldErrorCode,
			dataNumber1: resJson?.dataNumber1,
			httpCode: res.status
		};
	}

	if (res.status == 401) {
		return {
			errorMsg: "Unauthorized request.",
			httpCode: res.status
		};
	}

	if (res.status == 502) {
		return {
			errorMsg: "The backend server is down for updates. Please contact us if it doesn't come back online.",
			httpCode: res.status
		};
	}

	console.error("Unhandled response.", res);

	return {
		errorMsg: resText,
		httpCode: res.status
	};
}

function handleNetworkError(err: any): ErrorPayload {
	if (err.message == "Failed to fetch" || (err.message == "fetch failed" && err.cause?.code == "ECONNREFUSED")) {
		// Could not reach API.
		return {
			errorMsg: "Failed to reach the API server."
		};
	} else if (err.message == "The user aborted a request.") {
		// Req timeout.
		return {
			errorMsg: "Request timed out.",
		};
	} else {
		console.log("Unknown error in fetch", err);

		return {
			errorMsg: err.message
		};
	}
}

export default customInstance;

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<ErrorData> = ErrorPayload;

// In case you want to wrap the body type (optional)

// (if the custom instance is processing data before sending it, like changing the case for example)
export type BodyType<BodyData> = BodyData & { headers?: any };
